import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getShowSeedList, getShowSliceViews } from '../../modules/viewer/selectors';

import ctIcon from '../../images/ct-viewer-white.svg';
import seedIcon from '../../images/seed-list-white.svg';
import setShowSeedList from '../../modules/viewer/setShowSeedList';
import setShowSliceViews from '../../modules/viewer/setShowSliceViews';

export default function LeftToolbar() {
  const { showSliceViews, showSeedList } = useSelector(({ viewer }) => ({
    showSliceViews: getShowSliceViews(viewer),
    showSeedList: getShowSeedList(viewer),
  }));

  const dispatch = useDispatch();

  if (showSliceViews && showSeedList) {
    return null;
  }

  return (
    <Toolbar style={{ borderRight: !showSliceViews && !showSeedList ? '5px solid black' : 'none' }}>
      {!showSeedList && (
        <Btn onClick={() => dispatch(setShowSeedList(true))} data-test='max-seed-btn'>
          <img src={seedIcon} alt='' />
        </Btn>
      )}
      {!showSliceViews && (
        <Btn onClick={() => dispatch(setShowSliceViews(true))} data-test='max-ct-btn'>
          <img src={ctIcon} alt='' />
        </Btn>
      )}
    </Toolbar>
  );
}

const Toolbar = styled.div`
  background: #2b2b2b;
  width: 58px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
`;

const Btn = styled.button`
  border: none;
  background: none;
  margin-bottom: 10px;

  && {
    outline: none;
  }
`;
