import React, { useRef } from 'react';
import styled from 'styled-components';
import { useRenderEffect, useSceneSelector, useSceneEffect } from '@visionair/scene-state-3js';

import colors from '../../../stylesheets/theme/colors';
import { getSeedByID, getSliceIdx } from '../../../modules/scene/selectors';

const CIRCLE_RADIUS = 10;
const SVG_SIZE = CIRCLE_RADIUS * 2 + 2;

export default function SeedPoint({ id }) {
  const seedRef = useRef();

  const { seed, sliceIdx } = useSceneSelector(state => ({
    seed: getSeedByID(state, id),
    sliceIdx: getSliceIdx(state),
  }));

  useRenderEffect(
    sceneState => {
      const seedPoint = getSeedByID(sceneState, id);
      if (seedPoint && seedPoint.needsUpdate && seedRef.current) {
        seedRef.current.style.left = `${seedPoint.projPos.x - SVG_SIZE / 2}px`;
        seedRef.current.style.top = `${seedPoint.projPos.y - SVG_SIZE / 2}px`;

        seedPoint.needsUpdate = false;
      }
    },
    [id],
  );

  useSceneEffect(
    sceneState => {
      sceneState.render();
    },
    [seed],
  );

  if (!seed) {
    return null;
  }

  const colorStr = seed.pending ? colors.colorSeedPending : colors.colorSeedCompleted;

  return (
    <Seed
      width={`${SVG_SIZE}px`}
      height={`${SVG_SIZE}px`}
      isVisible={seed.sliceIdx === sliceIdx}
      ref={seedRef}
      data-test='seed-label'
    >
      <>
        <circle
          r={CIRCLE_RADIUS}
          cx='50%'
          cy='50%'
          stroke={colorStr}
          strokeWidth={1.5}
          fill='none'
        />
        {/* crosshair */}
        <line
          x1={SVG_SIZE / 2}
          y1={4}
          x2={SVG_SIZE / 2}
          y2={SVG_SIZE - 4}
          stroke={colorStr}
          strokeWidth={1.5}
        />
        <line
          x1={4}
          y1={SVG_SIZE / 2}
          x2={SVG_SIZE - 4}
          y2={SVG_SIZE / 2}
          stroke={colorStr}
          strokeWidth={1.5}
        />
      </>
    </Seed>
  );
}

const Seed = styled.svg`
  position: absolute;
  z-index: 3;

  pointer-events: none;
  ${props => !props.isVisible && 'display: none;'}
`;
