/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { useTable, useFlexLayout, useSortBy, useResizeColumns } from 'react-table';

import LoadingThreeDots from './loading/LoadingThreeDots';
import { debounced } from '../utils/general';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

export default function InfiniteScrollTable({
  containerRef,
  columns,
  data,
  defaultSorted,
  rowHeight,
  loadMoreRows,
  onRowClick,
  onRowSort,
  numTotalRows,
  isLoading,
  ...rest
}) {
  const [tableHeight, setTableHeight] = useState(100);

  const table = useTable(
    { columns, data, manualSortBy: true, initialState: { sortBy: defaultSorted } },
    useFlexLayout,
    useSortBy,
    useResizeColumns,
  );

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    const resize = debounced(100, () => {
      const headerHeight = 57;
      setTableHeight(containerRef.current.clientHeight - headerHeight);
    });

    // run one time when container is initialized then after each page resize
    resize();

    window.addEventListener('resize', resize);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', resize);
  }, [containerRef]);

  return (
    <S.InfiniteScrollTable {...table.getTableProps()} {...rest}>
      {table.headerGroups.map(headerGroup => (
        <S.THead {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => {
            let SortIcon = null;
            if (column.isSorted) {
              SortIcon = column.isSortedDesc ? FaCaretDown : FaCaretUp;
            }

            return (
              <S.TH
                key={column.id}
                {...column.getHeaderProps()}
                onClick={() => {
                  if (!column.canSort) {
                    return;
                  }

                  onRowSort(column.id, column.isSortedDesc ? SORT_ASC : SORT_DESC);
                  column.toggleSortBy(!column.isSortedDesc);
                }}
                className={[column.isResizing ? 'resizing' : '', column.headerClassName || ''].join(
                  ' ',
                )}
              >
                {column.render('Header')}
                {SortIcon && <SortIcon size='15' color='#b4b4b4' style={{ marginLeft: '20px' }} />}
                {column.canResize && <S.Resizer {...column.getResizerProps()} />}
              </S.TH>
            );
          })}
        </S.THead>
      ))}
      <S.TBody {...table.getTableBodyProps()}>
        <InfiniteLoader
          isItemLoaded={index => index < data.length}
          itemCount={numTotalRows}
          loadMoreItems={loadMoreRows}
          threshold={0}
        >
          {({ onItemsRendered, ref }) => (
            <FixedSizeList
              onItemsRendered={onItemsRendered}
              ref={ref}
              height={tableHeight}
              itemCount={numTotalRows}
              itemSize={rowHeight}
            >
              {({ index, style }) => {
                const row = table.rows[index];
                if (!row) {
                  return null;
                }

                table.prepareRow(row);

                return (
                  <S.TR
                    key={row.id}
                    {...row.getRowProps({
                      style,
                    })}
                    onClick={() => onRowClick(row.original)}
                    className={index % 2 === 0 ? 'even' : 'odd'}
                  >
                    {row.cells.map(cell => {
                      return (
                        <S.TD {...cell.getCellProps()} className={cell.column.cellClassName}>
                          {cell.render('Cell')}
                        </S.TD>
                      );
                    })}
                  </S.TR>
                );
              }}
            </FixedSizeList>
          )}
        </InfiniteLoader>
        {isLoading && (
          <S.Loader>
            <S.LoadingThreeDots
              numRows={numTotalRows}
              tableHeight={tableHeight}
              rowHeight={rowHeight}
              color='#b4b4b4'
            />
          </S.Loader>
        )}
      </S.TBody>
    </S.InfiniteScrollTable>
  );
}

const S = {};

S.InfiniteScrollTable = styled.div`
  color: white;
  height: 100%;
  background-color: #252525;

  .table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .center-cell {
    display: flex;
    justify-content: center;
  }
`;

S.THead = styled.div`
  background-color: #252525;
  font-size: 15px;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.fontFamilyDefault};
  height: 57px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

S.TH = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.resizing {
    pointer-events: none;
  }

  &:first-child {
    padding-left: 50px;
  }
`;

S.TBody = styled.div``;

S.TR = styled.div`
  cursor: pointer;
  background-color: #2d2d2d;
  color: ${props => props.theme.colors.white};
  border-top: 1px solid #4a4a4a;
  border-bottom: 1px solid #4a4a4a;

  &:hover {
    background-color: #3b3b3b;
  }
`;

S.TD = styled.div`
  text-align: left;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.fontFamilyDefault};
  align-self: center;

  &:first-child {
    padding-left: 50px;
  }
`;

S.Resizer = styled.div`
  height: 50px;
  width: 10px;
  position: absolute;
  right: 0;
`;

S.Loader = styled.div`
  position: absolute;
  left: 50%;
  width: 36px;
  transform: translateX(-50%);
  pointer-events: none;
`;

S.LoadingThreeDots = styled(LoadingThreeDots)`
  position: absolute;

  ${props => {
    const { numRows, tableHeight, rowHeight } = props;
    const numTableRows = tableHeight / rowHeight;
    if (numRows < numTableRows) {
      return `bottom: ${(numTableRows - numRows) * rowHeight - 40}px;`;
    }
    return 'bottom: 10px;';
  }}
`;
