import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import useScanSegs from '../../../hooks/useScanSegs';

import ButtonDefault from '../../buttons/ButtonDefault';

import startSegmentation from '../../../modules/segmentations/startSegmentation';

import { SEG_STATUS, SCAN_STATUS } from '../../../settings/segmentations';
import { showNotificationError } from '../../../modules/notifications/showNotification';

export default function RunSegBtn({ seg, scanStatus }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const scanSegs = useScanSegs();
  const isSegFromSeriesRunning = scanSegs.some(
    s => s.volume3DID === seg.volume3DID && s.status === SEG_STATUS.RUNNING,
  );

  const isDisabled =
    seg.status === SEG_STATUS.RUNNING ||
    scanStatus === SCAN_STATUS.VERIFIED ||
    scanStatus === SCAN_STATUS.REJECTED ||
    isSubmitting ||
    isSegFromSeriesRunning;

  const handleClick = () => {
    if (!isDisabled) {
      dispatch(startSegmentation(seg.id))
        .then(() => {
          setIsSubmitting(false);
        })
        .catch(err => {
          setIsSubmitting(false);
          // eslint-disable-next-line no-console
          console.error(err);
          dispatch(showNotificationError('There was a problem starting segmentation'));
        });
    }
  };

  return (
    // TODO: add confirmation modal
    // add warning when running seg without any changes, i.e. same thresh no pending seeds
    <Button onClick={handleClick} disabled={isDisabled}>
      {seg.status === SEG_STATUS.RUNNING ? 'Segmenting...' : 'Run Segmentation'}
    </Button>
  );
}

RunSegBtn.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  scanStatus: PropTypes.string.isRequired,
};

const Button = styled(ButtonDefault)`
  width: 215px;
  background-image: linear-gradient(to bottom, #2f64c4, #172e58);

  :hover {
    background-image: none;
    background-color: #2f64c4;
  }

  :active {
    background-image: none;
    border: solid 1px #2f64c4;
    background-color: #182f5a;
  }

  :disabled {
    background-image: none;
    border: solid 1px #2f64c4;
    background-color: #2f64c4;
    opacity: 0.5;
  }
`;
