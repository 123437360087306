import breadcrumbs from './breadcrumbs';
import buttons from './buttons';
import colors from './colors';
import fonts from './fonts';
import forms from './forms';
import modals from './modals';
import text from './text';
import tooltips from './tooltips';

export default {
  breadcrumbs: { ...breadcrumbs },
  buttons: { ...buttons },
  colors: { ...colors },
  fonts: { ...fonts },
  forms: { ...forms },
  modals: { ...modals },
  text: { ...text },
  tooltips: { ...tooltips },
};
