import { Map } from 'immutable';

import * as types from '../actions';

const INITIAL_STATE = Map({
  volID: null,
  segID: null,
  isPlacingSeed: false,
  isLoadingAirway: false,
  isLoadingCenterline: false,
  isCrossSectionVisible: false,
  isSelectingCenterlineStart: false,
  editSeedID: null,
  showSliceViews: true,
  showSeedList: true,
  showScanInfoTable: false,
  showCenterlineBoundsWarning: false,
});

function setVolID(state, volID) {
  return state.set('volID', volID);
}

function setSegID(state, segID) {
  return state.set('segID', segID);
}

function setIsPlacingSeed(state, isPlacingSeed) {
  return state.set('isPlacingSeed', isPlacingSeed);
}

function setIsLoadingAirway(state, isLoadingAirway) {
  return state.set('isLoadingAirway', isLoadingAirway);
}

function setIsLoadingCenterline(state, isLoadingCenterline) {
  return state.set('isLoadingCenterline', isLoadingCenterline);
}

function setEditSeedID(state, seedID) {
  return state.set('editSeedID', seedID).set('isPlacingSeed', Boolean(seedID));
}

function setIsCrossSectionVisible(state, visible) {
  return state.set('isCrossSectionVisible', visible);
}

function setIsSelectingCenterlineStart(state, isSelectingCenterlineStart) {
  return state.set('isSelectingCenterlineStart', isSelectingCenterlineStart);
}

function setShowSliceViews(state, show) {
  return state.set('showSliceViews', show);
}

function setShowSeedList(state, show) {
  return state.set('showSeedList', show);
}

function setShowScanInfoTable(state, show) {
  return state.set('showScanInfoTable', show);
}

function setShowCenterlineBoundsWarning(state, show) {
  return state.set('showCenterlineBoundsWarning', show);
}

export default function viewerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTH_LOGOUT:
    case types.VIEWER_RESET:
      return INITIAL_STATE;
    case types.VIEWER_SET_VOL_ID:
      return setVolID(state, action.volID);
    case types.VIEWER_SET_SEG_ID:
      return setSegID(state, action.segID);
    case types.VIEWER_SET_IS_PLACING_SEED:
      return setIsPlacingSeed(state, action.isPlacingSeed);
    case types.VIEWER_SET_IS_LOADING_AIRWAY:
      return setIsLoadingAirway(state, action.isLoadingAirway);
    case types.VIEWER_SET_IS_LOADING_CENTERLINE:
      return setIsLoadingCenterline(state, action.isLoadingCenterline);
    case types.VIEWER_SET_EDIT_SEED_ID:
      return setEditSeedID(state, action.seedID);
    case types.VIEWER_SET_IS_CROSS_SECTION_VISIBLE:
      return setIsCrossSectionVisible(state, action.visible);
    case types.VIEWER_IS_SELECTING_CENTERLINE_START:
      return setIsSelectingCenterlineStart(state, action.isSelectingCenterlineStart);
    case types.VIEWER_SET_SHOW_SEED_LIST:
      return setShowSeedList(state, action.showSeedList);
    case types.VIEWER_SET_SHOW_SLICE_VIEWS:
      return setShowSliceViews(state, action.showSliceViews);
    case types.VIEWER_SET_SHOW_SCAN_INFO_TABLE:
      return setShowScanInfoTable(state, action.show);
    case types.VIEWER_SET_SHOW_CENTERLINE_BOUNDS_WARNING:
      return setShowCenterlineBoundsWarning(state, action.showCenterlineBoundsWarning);
    default:
      return state;
  }
}
