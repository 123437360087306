import * as THREE from 'three';

import Volume3D from './Volume3DUtil';

const tempVec = new THREE.Vector3();

export default class SliceViewUtil {
  constructor(sliceView) {
    Object.entries(sliceView).forEach(([key, value]) => {
      this[key] = value;
    });

    this.orient = new THREE.Vector3(this.planeNormal.X, this.planeNormal.Y, this.planeNormal.Z);

    this.volume3D = new Volume3D(sliceView.volume3D);

    this.isAxialView = this.orientation.equals(new THREE.Vector3(0, 0, 1));
    this.isCoronalView = this.orientation.equals(new THREE.Vector3(0, 1, 0));
  }

  get orientation() {
    // check type in case it was serialized when passed to worker
    if (!(this.orient instanceof THREE.Vector3)) {
      this.orient = new THREE.Vector3(this.planeNormal.X, this.planeNormal.Y, this.planeNormal.Z);
    }

    return this.orient;
  }

  set upDir(up) {
    this.up = new THREE.Vector3(up.X, up.Y, up.Z);
  }

  get upDir() {
    // check type in case it was serialized when passed to worker
    if (!(this.up instanceof THREE.Vector3)) {
      this.up = new THREE.Vector3(this.up.x, this.up.y, this.up.z);
    }

    return this.up;
  }

  get xDir() {
    // check type in case it was serialized when passed to worker
    if (!(this.x instanceof THREE.Vector3)) {
      this.x = new THREE.Vector3().crossVectors(this.orientation, this.upDir);
    }

    return this.x;
  }

  get sliceThickness() {
    return Math.abs(this.volume3D.size.dot(this.orientation) / (this.numSlices - 1));
  }

  get size() {
    // check type in case it was serialized when passed to worker
    if (!(this.sliceSize instanceof THREE.Vector2)) {
      const x = Math.abs(this.xDir.dot(this.volume3D.size));
      const y = Math.abs(this.upDir.dot(this.volume3D.size));
      this.sliceSize = new THREE.Vector2(x, y);
    }

    return this.sliceSize;
  }

  get cameraPosition() {
    // check type in case it was serialized when passed to worker
    if (!(this.camPosition instanceof THREE.Vector3)) {
      this.camPosition = this.orientation
        .clone()
        .multiply(this.volume3D.size)
        .negate()
        .add(this.volume3D.center);
    }

    return this.camPosition;
  }

  calcSliceIdx(position) {
    let min = this.volume3D.boundingBox.min.dot(this.orientation);
    let max = this.volume3D.boundingBox.max.dot(this.orientation);

    if (max < min) {
      const temp = max;
      max = min;
      min = temp;
    }

    const boundingSize = max - min;
    const sliceLocation = position.dot(this.orientation);

    return Math.round(((sliceLocation - min) / boundingSize) * (this.numSlices - 1));
  }

  calcSlicePosition(sliceIdx) {
    return (sliceIdx / (this.numSlices - 1)) * this.volume3D.size.dot(this.orientation);
  }

  getSlicePlane(sliceIdx) {
    tempVec
      .copy(this.orientation)
      .multiplyScalar(
        (sliceIdx / (this.numSlices - 1)) * Math.abs(this.volume3D.size.dot(this.orientation)),
      )
      .add(this.volume3D.boundingBox.min);

    return new THREE.Plane().setFromNormalAndCoplanarPoint(this.orientation, tempVec);
  }
}
