import { ShaderMaterial, Color, Vector2, Vector3 } from 'three';

import vertexShader from './shaders/maskVertex.glsl';
import fragmentShader from './shaders/maskFragment.glsl';

export default class MaskMaterial extends ShaderMaterial {
  constructor() {
    const uniforms = {
      sliceIdx: { value: 0 },
      numSlices: { value: 0 },
      boundingBoxMin: { value: new Vector3() },
      boundingBoxMax: { value: new Vector3() },
      scanDirection: { value: new Vector3() }, // unit vector
      sliceSize: { value: new Vector3() },
      color: { value: new Color(0, 1, 0) },
      pixelSize: { value: new Vector2() },
      viewSize: { value: new Vector2() },
      canvasSize: { value: new Vector2() },
    };

    super({
      uniforms,
      vertexShader,
      fragmentShader,
    });
  }

  setSliceIdx(sliceIdx) {
    this.uniforms.sliceIdx.value = sliceIdx;
  }

  setColor(color) {
    this.uniforms.color.value.set(color);
  }

  setNumSlices(numSlices) {
    this.uniforms.numSlices.value = numSlices;
  }

  setImgDimensions(imgDimensions) {
    this.uniforms.pixelSize.value.set(imgDimensions.imgPixelWidth, imgDimensions.imgPixelHeight);
    this.uniforms.viewSize.value.set(imgDimensions.viewWidth, imgDimensions.viewHeight);
    this.uniforms.canvasSize.value.set(
      imgDimensions.canvasPixelWidth,
      imgDimensions.canvasPixelHeight,
    );
  }

  setSliceView(sliceView) {
    this.uniforms.numSlices.value = sliceView.numSlices;
    this.uniforms.boundingBoxMin.value.copy(sliceView.volume3D.boundingBox.min);
    this.uniforms.boundingBoxMax.value.copy(sliceView.volume3D.boundingBox.max);
    this.uniforms.scanDirection.value.copy(sliceView.orientation);
    this.uniforms.sliceSize.value.copy(sliceView.size);
  }
}
