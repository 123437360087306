import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { getIsPlacingSeed, getEditSeedID } from '../../../modules/viewer/selectors';
import setIsPlacingSeed from '../../../modules/viewer/setIsPlacingSeed';
import setEditSeedID from '../../../modules/viewer/setEditSeedID';

export default function AddSeedBtn({ className }) {
  const [isPlacingSeed, editSeedID] = useSelector(({ viewer }) => [
    getIsPlacingSeed(viewer),
    getEditSeedID(viewer),
  ]);
  const dispatch = useDispatch();

  let btnText = 'Add New Seed';
  if (isPlacingSeed) {
    btnText = !editSeedID ? 'Cancel Add' : 'Cancel Edit';
  }

  const handleClick = () => {
    if (editSeedID) {
      dispatch(setEditSeedID(null)); // updates isPlacingSeed
    } else {
      dispatch(setIsPlacingSeed(!isPlacingSeed));
    }
  };

  return (
    <Button className={className} onClick={handleClick}>
      {btnText}
      <PlusSign>+</PlusSign>
    </Button>
  );
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  height: 30px;
  padding: 0 15px;

  border-radius: 5px;
  border: solid 1px #707070;

  font-size: 12px;
  font-weight: 600;

  background: #202020;
  color: white;

  && {
    outline: none;
  }
`;

const PlusSign = styled.div`
  font-size: 25px;
  color: ${props => props.theme.colors.colorSeedPending};
  position: absolute;
  top: -7px;
  right: 15px;
`;
