import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { PersistGate } from 'redux-persist/lib/integration/react';
import jwt from 'jwt-decode';
import { renderer } from '@visionair/scene-state-3js';

import store, { persistor } from '../store';
import theme from '../stylesheets/theme';
import GlobalStyle from '../stylesheets/globalStyle';

import Routes from './Routes';

import { authLoginAction } from '../modules/auth/authLogin';
import setLogoutWarningTimeout from '../modules/auth/setLogoutWarningTimeout';

if (localStorage.getItem('token') !== null) {
  const token = localStorage.getItem('token');
  const expiresAtMs = jwt(token).exp * 1000;

  if (expiresAtMs <= Date.now()) {
    localStorage.removeItem('token');
  } else {
    store.dispatch(authLoginAction(token));
    store.dispatch(setLogoutWarningTimeout(token));
  }
}

window.renderer = renderer;

const Root = () => (
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Routes />
      </ThemeProvider>
    </PersistGate>
  </ReduxProvider>
);

export default Root;
