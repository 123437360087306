import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import useScanSegs from '../../../hooks/useScanSegs';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';

import verifySegmentation from '../../../modules/scene/actions/airwayView/verifySegmentation';
import { showNotificationError } from '../../../modules/notifications/showNotification';
import { SEG_STATUS, SCAN_STATUS } from '../../../settings/segmentations';
import checkCenterlineBounds from '../../../modules/scene/actions/airwayView/checkCenterlineBounds';
import { AIRWAY_VIEWER_NAME } from '../../../modules/scene/selectors';
import setShowCenterlineBoundsWarning from '../../../modules/viewer/setShowCenterlineBoundsWarning';

export default function VerifySegBtn({ seg, scanStatus }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();
  const scanSegs = useScanSegs();
  const history = useHistory();

  const centerlineExists = useSceneSelector(
    state => Boolean(state?.centerline),
    AIRWAY_VIEWER_NAME,
  );

  const handleBtnClick = () => {
    setShowModal(true);
  };

  const verify = useCallback(() => {
    const isEnclosedInAirway = sceneDispatch(checkCenterlineBounds(), AIRWAY_VIEWER_NAME);
    if (!isEnclosedInAirway) {
      reduxDispatch(setShowCenterlineBoundsWarning(true));
      setShowModal(false);
      return;
    }

    setIsSubmitting(true);

    sceneDispatch(verifySegmentation(), AIRWAY_VIEWER_NAME)
      .then(() => {
        setIsSubmitting(false);
        setShowModal(false);
        history.push('/scans');
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowModal(false);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while verifying segmentation.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [reduxDispatch, sceneDispatch, history]);

  const isDisabled =
    scanStatus === SCAN_STATUS.VERIFIED ||
    scanStatus === SCAN_STATUS.REJECTED ||
    seg.status !== SEG_STATUS.SUCCESS ||
    !centerlineExists;

  const runningSegs = scanSegs.filter(s => s.status === SEG_STATUS.RUNNING);

  return (
    <>
      <Button disabled={isDisabled} onClick={handleBtnClick}>
        Verify Segmentation
      </Button>
      <Modal show={showModal} centered backdropClassName='modal-backdrop-blur'>
        <Modal.Header>Verify Segmentation?</Modal.Header>
        <Modal.Body>
          {runningSegs.length > 0 && <h6>Warning! This scan has running segmentations.</h6>}
          <ul>
            {runningSegs.map(s => (
              <li key={s.id}>{s.segAlphaNum}</li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <CancelBtn disabled={isSubmitting} onClick={() => setShowModal(false)}>
            Cancel
          </CancelBtn>
          <ConfirmBtn disabled={isSubmitting} onClick={verify}>
            Verify
          </ConfirmBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
}

VerifySegBtn.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  scanStatus: PropTypes.string.isRequired,
};

const Button = styled(ButtonDefault)`
  width: 215px;
  background-image: linear-gradient(to top, #024334, #09a080);

  :hover {
    background-image: none;
    background-color: #09a080;
  }

  :active {
    background-image: none;
    border: solid 1px #09a080;
    background-color: #024536;
  }

  :disabled {
    background-image: none;
    background-color: #14715d;
    opacity: 0.5;
  }
`;

const CancelBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #09a080;
`;
