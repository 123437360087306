import { Iterable } from 'immutable';
import format from 'date-fns/format';
import add from 'date-fns/add';

let id = 1;

export function getID() {
  id += 1;
  return id;
}

export function convertImmutable(immutableReduxProps) {
  if (Iterable.isIterable(immutableReduxProps)) {
    return immutableReduxProps.toJS();
  }

  const jsProps = immutableReduxProps instanceof Array ? [] : {};

  Object.entries(immutableReduxProps).forEach(([key, value]) => {
    jsProps[key] = Iterable.isIterable(value) ? value.toJS() : value;
  });

  return jsProps;
}

export function debounced(delayMs, fn) {
  let timeout;
  return (...args) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      fn(...args);
      timeout = null;
    }, delayMs);
  };
}

export function formatDate(date, fmtStr = 'yyyy/MM/dd HH:mm OOOO') {
  if (!date) {
    return '';
  }

  return format(new Date(date), fmtStr);
}

// https://stackoverflow.com/a/44198641/5781184
export function isValidDate(date) {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);
}

// returns two dates - start date & end date string, encompassing a range determined by input length
// NOTE: input should match ISO 8601 format
// ex. input = 2020 -> year range
// start date: 2020-01-01T00:00:00.000Z
// end date: 2021-01-01T00:00:00.000Z
// ex. input = 2020-08 -> month range
// start date: 2020-08-01T00:00:00.000Z
// end date: 2020-09-01T00:00:00.000Z
// ex. input = 2020-08-14 -> day range
// start date: 2020-08-14T00:00:00.000Z
// end date: 2020-08-15T00:00:00.000Z
export function dateRangeFromISO8601Date(iso8601Date) {
  const startDate = new Date(iso8601Date);
  let endDate = new Date(startDate);

  if (!isValidDate(startDate)) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  const dateNoHyphens = iso8601Date.replace(/-/g, '');
  if (!dateNoHyphens.length) {
    return {
      startDate: null,
      endDate: null,
    };
  }

  if (dateNoHyphens.length === 'YYYY'.length) {
    // year search
    endDate = add(endDate, { years: 1 });
  }
  if (dateNoHyphens.length === 'YYYYMM'.length) {
    // month search
    endDate = add(endDate, { months: 1 });
  }
  if (dateNoHyphens.length === 'YYYYMMDD'.length) {
    // day search
    endDate = add(endDate, { days: 1 });
  }

  return {
    startDate,
    endDate,
  };
}
