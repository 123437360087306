import React, { useCallback, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import BoundingBoxTool from './BoundingBoxTool';
import DeleteTool from './DeleteTool';
import MoveCarinaTool from './MoveCarinaTool';
import SmoothingTool from './SmoothingTool';
import MergeCarinaTool from './MergeCarinaTool';
import GenerationTool from './GenerationTool';
import AddBranchTool from './AddBranchTool';
import AdjustToCentroidTool from './AdjustToCentroidTool';
import CenterlineStartingPoint from './CenterlineStartingPoint';
import ToolBtn from './ToolBtn';
import BBVisToggleBtn from './BBVisToggleBtn';
import SliceVisToggleBtn from './SliceVisToggleBtn';
import CenterlineBoundsWarning from './CenterlineBoundsWarning';

import snapshotCenterlineState from '../../../modules/scene/actions/airwayView/snapshotCenterlineState';
import resetCenterline from '../../../modules/scene/actions/airwayView/resetCenterline';

import addBranchIcon from '../../../images/centerline_tools/add_branch.svg';
import addBranchIconActive from '../../../images/centerline_tools/add_branch_active.svg';
import bbIcon from '../../../images/centerline_tools/bb.svg';
import bbIconActive from '../../../images/centerline_tools/bb_active.svg';
import centerlineStartIcon from '../../../images/centerline_tools/centerline_start.svg';
import centerlineStartIconActive from '../../../images/centerline_tools/centerline_start_active.svg';
import centroidIcon from '../../../images/centerline_tools/centroid.svg';
import centroidIconActive from '../../../images/centerline_tools/centroid_active.svg';
import deleteIcon from '../../../images/centerline_tools/delete.svg';
import deleteIconActive from '../../../images/centerline_tools/delete_active.svg';
import genIcon from '../../../images/centerline_tools/gen.svg';
import genIconActive from '../../../images/centerline_tools/gen_active.svg';
import mergeIcon from '../../../images/centerline_tools/merge.svg';
import mergeIconActive from '../../../images/centerline_tools/merge_active.svg';
import moveIcon from '../../../images/centerline_tools/move_carina.svg';
import moveIconActive from '../../../images/centerline_tools/move_carina_active.svg';
import smoothIcon from '../../../images/centerline_tools/smooth.svg';
import smoothIconActive from '../../../images/centerline_tools/smooth_active.svg';

const [
  MOVE_CARINA,
  SMOOTH,
  DELETE,
  ADD_NEW,
  BB,
  GEN_TRUNC,
  CENTROID,
  CENTERLINE_START,
  MERGE,
] = new Array(9).fill().map((_, i) => i);

export default function CenterlineTools() {
  const [activeTool, setActiveTool] = useState(null);
  const [toolComplete, setToolComplete] = useState(false);
  const dispatch = useSceneDispatch();
  const shouldSaveCenterlineRef = useRef(false);

  const { canvasHeight, isCenterlineLoaded } = useSceneSelector(state => ({
    canvasHeight: state.canvas?.clientHeight,
    isCenterlineLoaded: Boolean(state.centerline),
  }));

  useEffect(() => {
    if (activeTool == null) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return () => {
      // snapshot centerline after the tool comp has had a chance to "unmount"
      // this allows any centerline changes that may occur within tool comp's useEffect cleanp funcs
      // to be executed before the snapshot occurs
      if (shouldSaveCenterlineRef.current) {
        dispatch(snapshotCenterlineState());
        shouldSaveCenterlineRef.current = false;
      }
    };
  }, [activeTool, dispatch]);

  const handleToolFinish = useCallback(() => {
    setToolComplete(true);
  }, []);

  if (!isCenterlineLoaded) {
    return null;
  }

  const handleCancel = () => {
    if (toolComplete) {
      dispatch(resetCenterline());
    }

    shouldSaveCenterlineRef.current = false;

    setToolComplete(false);
    setActiveTool(null);
  };

  const handleConfirm = () => {
    shouldSaveCenterlineRef.current = true;

    setToolComplete(false);
    setActiveTool(null);
  };

  const handleToolClick = tool => {
    // when bounding box or centerline start tool is selected ignore btn clicks
    if (activeTool === BB || activeTool === CENTERLINE_START) {
      return;
    }

    if (tool === activeTool) {
      setActiveTool(null);
      dispatch(resetCenterline());
    } else {
      setActiveTool(tool);
    }
  };

  let toolComp = null;

  switch (activeTool) {
    case BB:
      toolComp = (
        <BBTool
          onFinish={() => {
            setToolComplete(false);
            setActiveTool(null);
          }}
        />
      );
      break;
    case MOVE_CARINA:
      toolComp = <MoveCarinaTool onFinish={handleToolFinish} />;
      break;
    case SMOOTH:
      toolComp = <SmoothingTool onFinish={handleToolFinish} />;
      break;
    case DELETE:
      toolComp = <DeleteTool onFinish={handleToolFinish} />;
      break;
    case MERGE:
      toolComp = <MergeCarinaTool onFinish={handleToolFinish} />;
      break;
    case GEN_TRUNC:
      toolComp = <GenerationTool onFinish={handleToolFinish} />;
      break;
    case ADD_NEW:
      toolComp = <AddBranchTool onFinish={handleToolFinish} />;
      break;
    case CENTROID:
      toolComp = <CentroidTool onFinish={handleToolFinish} />;
      break;
    case CENTERLINE_START:
      toolComp = (
        <CenterlineStartingPoint
          onFinish={() => {
            setToolComplete(false);
            setActiveTool(null);
          }}
        />
      );
      break;
    default:
  }

  return (
    <Container>
      <CenterlineBoundsWarning />
      {toolComp}
      <BtnPanel style={{ height: `${canvasHeight - 40}px` }} data-test='centerline-tools'>
        <ToolBtn
          active={activeTool === MOVE_CARINA}
          tooltip='Move Carina'
          onClick={() => handleToolClick(MOVE_CARINA)}
          data-test='move-carina-tool'
        >
          <Icon src={activeTool === MOVE_CARINA ? moveIconActive : moveIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === SMOOTH}
          tooltip='Smooth Centerline'
          onClick={() => handleToolClick(SMOOTH)}
          data-test='smooth-tool'
        >
          <Icon src={activeTool === SMOOTH ? smoothIconActive : smoothIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === DELETE}
          tooltip='Delete Segment'
          onClick={() => handleToolClick(DELETE)}
          data-test='delete-tool'
        >
          <Icon src={activeTool === DELETE ? deleteIconActive : deleteIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === ADD_NEW}
          tooltip='Add New Branch'
          onClick={() => handleToolClick(ADD_NEW)}
          data-test='add-branch-tool'
        >
          <Icon src={activeTool === ADD_NEW ? addBranchIconActive : addBranchIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === BB}
          tooltip='Bounding Box Truncation'
          onClick={() => handleToolClick(BB)}
          data-test='bounding-box-tool'
        >
          <Icon src={activeTool === BB ? bbIconActive : bbIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === GEN_TRUNC}
          tooltip='Generation Select'
          onClick={() => handleToolClick(GEN_TRUNC)}
          data-test='generation-select-tool'
        >
          <Icon src={activeTool === GEN_TRUNC ? genIconActive : genIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === CENTROID}
          tooltip='Adjust Center Point'
          onClick={() => handleToolClick(CENTROID)}
          data-test='center-point-tool'
        >
          <Icon src={activeTool === CENTROID ? centroidIconActive : centroidIcon} />
        </ToolBtn>
        <ToolBtn
          active={activeTool === CENTERLINE_START}
          tooltip='Centerline Start'
          onClick={() => handleToolClick(CENTERLINE_START)}
          data-test='centerline-start-tool'
        >
          <Icon
            src={activeTool === CENTERLINE_START ? centerlineStartIconActive : centerlineStartIcon}
          />
        </ToolBtn>
        <ToolBtn
          active={activeTool === MERGE}
          tooltip='Merge Carina Points'
          onClick={() => handleToolClick(MERGE)}
          data-test='merge-carina-tool'
        >
          <Icon src={activeTool === MERGE ? mergeIconActive : mergeIcon} />
        </ToolBtn>
        <VisTogglesContainer>
          <BBVisToggleBtn />
          <SliceVisToggleBtn />
        </VisTogglesContainer>
      </BtnPanel>
      {activeTool != null && activeTool !== BB && activeTool !== CENTERLINE_START && (
        <FooterBtns style={{ bottom: `-${canvasHeight - 20}px` }}>
          <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
          <ConfirmBtn disabled={!toolComplete} onClick={handleConfirm}>
            Confirm
          </ConfirmBtn>
        </FooterBtns>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
`;

const BBTool = styled(BoundingBoxTool)`
  position: absolute !important;
`;

const CentroidTool = styled(AdjustToCentroidTool)`
  position: absolute !important;
  pointer-events: none;
`;

const BtnPanel = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  padding: 6px;
  border-radius: 6px;
  border: solid 1px #707070;
  background-color: #202020;
`;

const VisTogglesContainer = styled.div`
  position: absolute;
  bottom: 6px;
  left: 6px;
`;

const FooterBtns = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const CancelBtn = styled.button`
  background: #393939;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-right: 10px;
`;

const ConfirmBtn = styled.button`
  background: #19822d;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  width: 160px;
  height: 35px;
  margin-left: 10px;

  ${props => props.disabled && 'opacity: 0.4'}
`;

const Icon = styled.img``;
