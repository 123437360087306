/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { MdClear, MdSearch } from 'react-icons/md';

import { DateMask, ScanNumMask } from '../MaskedInput';

import ScanStatusFilters from './ScanStatusFilters';

import useSelectorToJS from '../../hooks/useSelectorToJS';
import useDebounce from '../../hooks/useDebounce';

import { getScansSearch } from '../../modules/scansTable/selectors';
import setScansSearch from '../../modules/scansTable/setScansSearch';
import { searchFields } from '../../modules/scansTable/reducer';

export default function ScanSearch() {
  const dispatch = useDispatch();

  const { search } = useSelectorToJS(({ scansTable }) => ({
    search: getScansSearch(scansTable),
  }));

  const [searchValue, setSearchValue] = useState('');

  const searchValueDebounced = useDebounce(searchValue, 500);
  useEffect(() => {
    dispatch(setScansSearch(undefined, searchValueDebounced));
  }, [searchValueDebounced, dispatch]);

  const handleClearInputGlyphClick = () => {
    setSearchValue('');
  };

  const handleSelectChange = evt => {
    dispatch(setScansSearch(evt.target.value));
    setSearchValue('');
  };

  const handleInputChange = evt => {
    setSearchValue(evt.target.value);
  };

  return (
    <Container>
      <Row>
        <ScansSearchContainer>
          <div>
            <MdSearch size={24} color='#6b6b6b' style={{ marginRight: '10px' }} />
          </div>
          {search.field !== searchFields.SCAN_NUM && search.field !== searchFields.UPLOAD_DATE && (
            <TextInput
              type='text'
              value={searchValue}
              onChange={handleInputChange}
              placeholder='Search Scans by ...'
              data-test='scans-search-textfield'
            />
          )}
          {search.field === searchFields.SCAN_NUM && (
            <ScanNumMask
              type='text'
              value={searchValue}
              onChange={handleInputChange}
              placeholder='Search Scans by ...'
              data-test='scans-search-textfield'
              render={(ref, props) => <TextInput ref={ref} {...props} />}
              autoFocus
            />
          )}
          {search.field === searchFields.UPLOAD_DATE && (
            <DateMask
              type='text'
              value={searchValue}
              onChange={handleInputChange}
              placeholder='YYYY-MM-DD'
              data-test='scans-search-textfield'
              render={(ref, props) => <TextInput ref={ref} {...props} />}
            />
          )}
          <div>
            {searchValue.length !== 0 && (
              <ClearButton onClick={handleClearInputGlyphClick} data-test='scans-search-clear'>
                <MdClear size={22} color='white' />
              </ClearButton>
            )}
          </div>
          <SelectSearchField
            data-test='scans-search-field-select'
            value={search.field}
            onChange={handleSelectChange}
            disableUnderline
          >
            <MenuItem value={searchFields.SCAN_NUM}>CT Number</MenuItem>
            <MenuItem value={searchFields.PATIENT_NAME}>Patient Name</MenuItem>
            <MenuItem value={searchFields.DOC_NAME}>Physician Name</MenuItem>
            <MenuItem value={searchFields.UPLOAD_DATE}>Upload Date</MenuItem>
          </SelectSearchField>
        </ScansSearchContainer>
      </Row>
      <Row>
        <ScanStatusFilters />
      </Row>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  .form-group {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  :first-of-type {
    padding-bottom: 30px;
  }
`;

const ScansSearchContainer = styled.div`
  width: 450px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  background-color: #272727;
  color: white;

  border-radius: 5px;
  border: solid 2px #3d3d3d;
  overflow: hidden;

  &:hover {
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
  }
`;

const TextInput = styled.input`
  height: 40px;
  width: 100%;
  background-color: #272727;
  color: white;
  font-family: ${props => props.theme.fonts.fontFamilyDefault};
  border-radius: 3px;
  border-width: 0px;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
  }
`;

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;
  border-radius: 3px;
  border-width: 0px;
  background: none;

  &:hover,
  &:active,
  &:focus {
    outline: none !important;
  }

  ${props => {
    const disableButton =
      props.isDisabled &&
      css`
        background-color: ${props.theme.colors.white};
        pointer-events: none;
      `;
    return disableButton;
  }}
`;

const SelectSearchField = styled(Select)`
  font-size: 14px;
  color: white;

  svg {
    color: white;
  }

  .MuiSelect-select.MuiSelect-select {
    padding: 13px 30px 11px 18px;
    font-weight: 500;
    color: white;
  }

  .MuiSelect-icon {
    right: 5px;
  }

  .MuiSelect-select:focus {
    background-color: inherit;
  }
`;
