import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiSlider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/styles';
import styled from 'styled-components';

import { getSegSensitivity } from '../../../modules/segmentations/selectors';
import { DEFAULT_SENSITIVITY } from '../../../settings/segmentations';
import saveSeg from '../../../modules/segmentations/saveSeg';

const Slider = withStyles({
  root: {},
  thumb: {
    color: '#00f837',
  },
  track: {
    color: '#00f837',
  },
})(MuiSlider);

export default function SensitivitySlider({ segID }) {
  const [displayedSens, setDisplayedSens] = useState(DEFAULT_SENSITIVITY);
  const dbSens = useSelector(({ segmentations }) => getSegSensitivity(segmentations, segID));

  const reduxDispatch = useDispatch();

  useEffect(() => {
    // initialize slider to match seg sensitivity
    setDisplayedSens(dbSens || DEFAULT_SENSITIVITY);
  }, [dbSens, segID, reduxDispatch]);

  const handleChange = (evt, newVal) => {
    setDisplayedSens(newVal);
  };

  const handleMouseUp = () => {
    reduxDispatch(saveSeg(segID, { sensitivity: displayedSens }));
  };

  return (
    <S.SensitivitySlider>
      <span>Sensitivity:</span>
      <span style={{ margin: '0 15px' }}>{displayedSens}</span>
      <Slider
        value={displayedSens}
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        min={1}
        max={10}
        step={1}
        data-test='sensitivity-slider'
      />
    </S.SensitivitySlider>
  );
}

const S = {};

S.SensitivitySlider = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  color: white;
  font-size: 12px;
`;
