import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as THREE from 'three';
import { useSceneDispatch } from '@visionair/scene-state-3js';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';

import { SCAN_STATUS } from '../../../settings/segmentations';

import editLogo from '../../../images/edit.svg';
import removeLogo from '../../../images/delete.svg';

import setSliceIdxByPosition from '../../../modules/scene/actions/sliceView/setSliceIdxByPosition';
import deleteSeed from '../../../modules/segmentations/removeSeed';
import setEditSeedID from '../../../modules/viewer/setEditSeedID';

import { showNotificationError } from '../../../modules/notifications/showNotification';
import { CORONAL_VIEWER_NAME, AXIAL_VIEWER_NAME } from '../../../modules/scene/selectors';

export default function SeedListBtn({ children, className, seed, scanStatus }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const sceneDispatch = useSceneDispatch();

  const showSeedPoint = () => {
    sceneDispatch(
      setSliceIdxByPosition(new THREE.Vector3().fromArray(seed.position)),
      AXIAL_VIEWER_NAME,
    );
    sceneDispatch(
      setSliceIdxByPosition(new THREE.Vector3().fromArray(seed.position)),
      CORONAL_VIEWER_NAME,
    );
  };

  const removeSeed = () => {
    setIsSubmitting(true);
    dispatch(deleteSeed(seed.id))
      .then(() => {
        setIsSubmitting(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        setShowModal(false);
        dispatch(showNotificationError('An unexpected error occurred while removing seed.'));
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  const editSeed = () => {
    dispatch(setEditSeedID(seed.id));
  };

  return (
    <>
      <BtnContiner>
        <ShowSeedBtn className={className} onClick={showSeedPoint} isPending={seed.pending}>
          {children}
          {seed.autoGenerated && <AutoText>(Auto)</AutoText>}
        </ShowSeedBtn>
        {scanStatus === SCAN_STATUS.UPLOADED && (
          <SeedOptions>
            <EditBtn onClick={editSeed}>
              <img src={editLogo} alt='edit' />
            </EditBtn>
            <EditBtn onClick={() => setShowModal(true)}>
              <img src={removeLogo} alt='remove' />
            </EditBtn>
          </SeedOptions>
        )}
      </BtnContiner>
      <Modal show={showModal} centered backdropClassName='modal-backdrop-blur'>
        <Modal.Header>Confirm Delete Seed</Modal.Header>
        <Modal.Body>
          Are you sure you want to remove this seed?
          <br />
          <br />
          {seed.pending && 'This action cannot be undone.'}
        </Modal.Body>
        <Modal.Footer>
          <CloseBtn disabled={isSubmitting} onClick={() => setShowModal(false)}>
            Cancel
          </CloseBtn>
          <ConfirmBtn disabled={isSubmitting} onClick={removeSeed}>
            Remove Seed
          </ConfirmBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const SeedOptions = styled.div`
  padding-right: 20px;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  background: #1d1d1d;
  border-bottom: 1px solid black;
`;

const ShowSeedBtn = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex: 1 1 175px;
  width: 100%;
  height: 50px;
  background: #1d1d1d;
  font-size: 14px;
  color: white;
  padding-left: 59px;
  border-bottom: 1px solid black;
  border-right: none;
  border-top: none;
  border-left: none;

  &:focus {
    border-left: 5px solid
      ${props =>
        props.isPending
          ? props.theme.colors.colorSeedPending
          : props.theme.colors.colorSeedCompleted};
    padding-left: 54px;
  }

  && {
    outline: none;
  }
`;

const BtnContiner = styled.div`
  height: 50px;
  display: flex;

  &:hover {
    ${SeedOptions} {
      background: #2b2b2b;
    }

    ${ShowSeedBtn} {
      background: #2b2b2b;
    }
  }
`;

const EditBtn = styled.button`
  margin: 0 10px;
  background: #393939;
  border: none;
  border-radius: 2px;
  height: 26px;
  width: 31px;
`;

const AutoText = styled.div`
  margin-left: 20px;
  font-size: 14px;
  color: ${props => props.theme.colors.colorSeedCompleted};
`;

const CloseBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #aa0014;
`;
