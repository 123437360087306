import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import TableBs from 'react-bootstrap/Table';

import ContainerOverlay from '../modals/ContainerOverlay';

import useSelectorToJS from '../../hooks/useSelectorToJS';
import { getVolByID } from '../../modules/volume3Ds/selectors';
import { getShowScanInfoTable } from '../../modules/viewer/selectors';

export default function ScanInfoTable({ volID, containerRef }) {
  const show = useSelector(({ viewer }) => getShowScanInfoTable(viewer));

  const { vol3D } = useSelectorToJS(({ volume3Ds }) => ({
    vol3D: getVolByID(volume3Ds, volID),
  }));

  if (!vol3D) {
    return null;
  }

  return (
    <Overlay
      containerRef={containerRef}
      show={show}
      backdropClassName='backdrop-scanInfoTable'
      autoFocus={false}
      enforceFocus={false}
      animation={false}
    >
      <Table striped data-test='scan-info-table'>
        <thead>
          <tr>
            <th style={{ width: '250px' }}>Field Name</th>
            <th style={{ width: '175px' }}>Tag</th>
            <th>Content</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TransferSyntaxUID</td>
            <td>0002, 0010</td>
            <td>{vol3D.transferSyntaxUID}</td>
          </tr>
          <tr>
            <td>AcquisitionDate</td>
            <td>0008, 0022</td>
            <td>{vol3D.scanDate}</td>
          </tr>
          <tr>
            <td>StudyDescription</td>
            <td>0008, 1030</td>
            <td>{vol3D.studyDescription}</td>
          </tr>
          <tr>
            <td>SeriesDescription</td>
            <td>0008, 103e</td>
            <td>{vol3D.seriesDescription}</td>
          </tr>
          <tr>
            <td>SliceThickness</td>
            <td>(calculated)</td>
            <td>{vol3D.sliceThickness.toFixed(2)}</td>
          </tr>
          <tr>
            <td>CTDIvol</td>
            <td>0018, 9345</td>
            <td>{vol3D.dose}</td>
          </tr>
          <tr>
            <td>StudyInstanceUID</td>
            <td>0020, 000d</td>
            <td>{vol3D.studyInstanceUID}</td>
          </tr>
          <tr>
            <td>SeriesInstanceUID</td>
            <td>0020, 000e</td>
            <td>{vol3D.seriesInstanceUID}</td>
          </tr>
          <tr>
            <td>StudyID</td>
            <td>0020, 0010</td>
            <td>{vol3D.studyID}</td>
          </tr>
          <tr>
            <td>SeriesNumber</td>
            <td>0020, 0011</td>
            <td>{vol3D.seriesNumber}</td>
          </tr>
          <tr>
            <td>ConvolutionKernel</td>
            <td>0018, 1210</td>
            <td>{vol3D.convolutionKernel}</td>
          </tr>
        </tbody>
      </Table>
    </Overlay>
  );
}

const Overlay = styled(ContainerOverlay)`
  .modal-dialog {
    max-width: 100%;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background-color: #000000;

    border: none;
    border-radius: 0;

    .modal-body {
      display: flex;
      padding: 0 0 0 5px;
      flex: 0 0 auto;
    }
  }
`;

const Table = styled(TableBs)`
  color: #ffffff;

  thead th {
    height: 35px;
    padding: 0 30px;

    background-color: #404040;

    border: none;
    border-right: 1px solid #000000;

    font-size: 12px;
    font-weight: 700;
    vertical-align: middle;
  }

  tbody tr:nth-of-type(odd) {
    background-color: #2b2b2b;
  }

  tbody tr:nth-of-type(even) {
    background-color: #1f1f1f;
  }

  tbody tr:hover {
    background-color: #2b5d5b;
  }

  tbody td {
    height: 30px;
    padding: 0 30px;

    border: none;
    border-right: 1px solid #000000;

    font-size: 12px;
    font-weight: 500;
    vertical-align: middle;
  }
`;
