import * as THREE from 'three';

import SliceView from '../../../utils/SliceView';
import {
  getAirwayMask,
  getCamera,
  getSliceImageState,
  getCanvas,
  AIRWAY_VIEWER_NAME,
} from '../selectors';
import initializeSlicePlanes from './airwayView/initializeSlicePlanes';
import setSliceImageState from './sliceView/setSliceImageState';
import updateSeedPositions from './sliceView/updateSeedPositions';

export default function initializeSliceLoader(canvas, sliceView) {
  return async (notify, getSceneState, callSceneAction) => {
    const sceneState = getSceneState();
    const camera = getCamera(sceneState);

    if (!sceneState.sliceLoader) {
      const loader = new SliceView(canvas, sliceView);
      loader.initializeDisplayCallback((displayedSliceIdx, imgDimensions) => {
        sceneState.sliceIdx = displayedSliceIdx;
        const mask = getAirwayMask(sceneState);
        if (mask) {
          mask.material.setSliceIdx(displayedSliceIdx);
          mask.material.setImgDimensions(imgDimensions);
        }

        const zoom = imgDimensions.dWidth / imgDimensions.viewWidth;
        if (camera.zoom !== zoom) {
          camera.zoom = zoom;
          camera.updateProjectionMatrix();
        }

        const sliceImageState = getSliceImageState(sceneState);
        sliceImageState.sCanvas.set(imgDimensions.canvasX, imgDimensions.canvasY);

        const threeCanvas = getCanvas(sceneState);
        const cameraOffsetX =
          (imgDimensions.canvasX / threeCanvas.width) * sceneState.sliceView.size.x;
        const cameraOffsetY =
          (imgDimensions.canvasY / threeCanvas.height) * sceneState.sliceView.size.y;

        const cameraOffset = new THREE.Vector3()
          .addVectors(
            sceneState.sliceView.xDir.clone().multiplyScalar(cameraOffsetX),
            sceneState.sliceView.upDir.clone().multiplyScalar(-cameraOffsetY),
          )
          .add(sceneState.sliceView.cameraPosition);

        camera.position.copy(cameraOffset);

        callSceneAction(updateSeedPositions());

        sceneState.isSliceLoaderInitialized = true;

        sceneState.render();

        notify();
      });

      sceneState.sliceLoader = loader;
    } else {
      sceneState.sliceLoader.initializeSliceView(sliceView);
    }

    sceneState.sliceView = sliceView;
    if (sliceView.isAxialView) {
      callSceneAction(initializeSlicePlanes(), AIRWAY_VIEWER_NAME);
    }

    const token = localStorage.getItem('token');

    const initialSliceIdx = sliceView.isCoronalView ? 0 : sliceView.numSlices - 1;
    await sceneState.sliceLoader.loadSlices(initialSliceIdx, 1, token);
    sceneState.sliceLoader.displaySlice(initialSliceIdx, token);

    camera.up.copy(sliceView.upDir);
    camera.position.copy(sliceView.cameraPosition);
    camera.lookAt(sliceView.volume3D.center);

    callSceneAction(setSliceImageState(0, 0, 0));
    notify();

    return sceneState.sliceLoader;
  };
}
