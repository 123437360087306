/* eslint-disable max-classes-per-file */
import VFFLoader from 'three-vff-loader';
import { Vector3 } from 'three';

import * as urls from '../settings/api';
import store from '../store';
import authSetConnected from '../modules/auth/authSetConnected';
import { getMaskVFFURL, getMaskMHDURL } from '../settings/api';

export const httpStatusCodes = {
  OK: 200,
  CREATED: 201,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  INTERNAL_SERVER_ERROR: 500,
};

export const httpErrorMessages = {
  BAD_REQUEST: 'Bad request.',
  UNAUTHORIZED: 'Unauthorized',
  NOT_FOUND: 'Resource not found.',
  DEFAULT: 'An unexpected error was encountered. Please try again.',
};

export class HttpError extends Error {
  constructor(response, message) {
    super(message);
    this.name = this.constructor.name;
    this.response = response;

    Error.captureStackTrace(this, this.constructor); // remove constructor from stack trace
  }
}

export class HttpBadRequestError extends HttpError {
  constructor(response, message = httpErrorMessages.BAD_REQUEST) {
    super(response, message);
  }
}

export class HttpUnauthorizedError extends HttpError {
  constructor(response, message = httpErrorMessages.UNAUTHORIZED) {
    super(response, message);
  }
}

export class HttpNotFoundError extends HttpError {
  constructor(response, message = httpErrorMessages.NOT_FOUND) {
    super(response, message);
  }
}

export class HttpInternalServerError extends HttpError {
  constructor(response, message = httpErrorMessages.DEFAULT) {
    super(response, message);
  }
}

export function getUser(userID, token) {
  return apiFetch(urls.getUserURL(userID), token, 'GET');
}

export function updateUser(userID, updateObj, token) {
  return apiFetch(urls.updateUserURL(userID), token, 'PATCH', updateObj);
}

export function updateSeg(seg) {
  return apiFetch(urls.patchSegURL(seg.id), localStorage.getItem('token'), 'PATCH', seg);
}

export function searchScans({
  scanNum,
  doctorName,
  patientName,
  scanStatus, // can supply array of statuses
  scanSegStatus, // can supply array of statuses
  acqDate, // supply start/end dates for range
  uploadDate, // supply start/end dates for range
  verifRejDate, // supply start/end dates for range
  withSegs,
  sortField,
  sortDir,
  limit,
  offset,
}) {
  const searchValues = {
    scanNum,
    doctorName,
    patientName,
    scanStatus,
    scanSegStatus,
    acqDate,
    uploadDate,
    verifRejDate,
    withSegs,
    limit,
    offset,
  };

  let url = urls.searchScansURL(searchValues);
  if (sortField) {
    url = `${url}&sort=${sortField}:${sortDir}`;
  }

  return apiFetch(url, localStorage.getItem('token'), 'GET');
}

export function apiFetch(path, ...args) {
  const url = fullUrl(path);
  const init = initialize(...args);
  return fetch(url, init)
    .catch(err => {
      store.dispatch(authSetConnected(false));
      throw err;
    })
    .then(parseResponse);
}

export async function fetchFileWithProgress(fileURL, token, onProgressCallback = () => {}) {
  const response = await fetch(fullUrl(fileURL), initialize(token), 'GET');
  if (!response.ok) {
    throw new Error('Not found');
  }
  if (!response.body) {
    throw Error('ReadableStream unsupported by this browser');
  }

  const contentLength = response.headers.get('content-length');
  if (!contentLength) {
    throw Error('Content-Length response header not found');
  }

  const totalFileSize = parseInt(contentLength, 10);
  if (totalFileSize === -1) {
    throw Error('Content-Length unknown');
  }

  let loaded = 0;

  const reader = response.body.getReader();

  const stream = new ReadableStream({
    start(controller) {
      function read() {
        return reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return;
          }

          loaded += value.byteLength;
          onProgressCallback({ loaded, total: totalFileSize });

          controller.enqueue(value);
          read();
        });
      }

      read();
    },
  });

  return new Response(stream).arrayBuffer();
}

export const fetchScanFile = (scanID, fileName, token) => {
  const url = urls.getScanFileURL(scanID, fileName);

  return fetch(fullUrl(url), initialize(token, 'GET')).then(res => {
    if (!res.ok) {
      throw new Error('Not found');
    }

    return res.arrayBuffer();
  });
};

export function fetchStentFile(stentID, fileName, token) {
  const url = fullUrl(urls.getStentFileURL(stentID, fileName));

  return fetch(url, initialize(token, 'GET')).then(res => {
    if (!res.ok) {
      throw parseResponseError(res);
    }

    return res.blob();
  });
}

export function fullUrl(path = '') {
  return urls.API_BASE_URL + path;
}

export function initialize(token, method = 'GET', data, form) {
  const headers = new Headers();
  if (!form) {
    headers.append('Content-Type', 'application/json');
  }
  if (token !== undefined) {
    addAuthHeader(token, headers);
  }

  let body;
  if (form === true) {
    const formdata = addFormData(data);
    body = formdata;
  } else if (data !== undefined) {
    body = JSON.stringify(data);
  }

  return {
    method,
    headers,
    body,
  };
}

export function addAuthHeader(token, headers = new Headers()) {
  headers.append('Authorization', `JWT ${token}`);
  return headers;
}

export function addFormData(data, formData = new FormData()) {
  Object.entries(data).forEach(([name, val]) => {
    formData.append(name, val);
  });

  return formData;
}

export function parseResponse(response) {
  // assume it's a fetch request if not XHR
  return isXHR(response) ? parseXHRResponse(response) : parseFetchResponse(response);
}

export function isXHR(response) {
  return response.constructor.name === 'XMLHttpRequest';
}

export function parseXHRResponse(xhrResponse) {
  const { status, response } = xhrResponse;

  if (status / 100 !== 2) {
    // ERROR
    throw parseResponseError(xhrResponse);
  }

  return response;
}

export function parseFetchResponse(fetchResponse) {
  if (!fetchResponse.ok) {
    // ERROR
    throw parseResponseError(fetchResponse);
  }

  return fetchResponse.json();
}

function parseResponseError(response) {
  let error;

  switch (response.status) {
    case httpStatusCodes.BAD_REQUEST:
      error = new HttpBadRequestError(response);
      break;
    case httpStatusCodes.UNAUTHORIZED:
      error = new HttpUnauthorizedError(response);
      break;
    case httpStatusCodes.NOT_FOUND:
      error = new HttpNotFoundError(response);
      break;
    default:
      error = new HttpInternalServerError(response);
      break;
  }

  throw error;
}

export function uploadFile(file, url, apiFieldName, opts, onProgressCallback = () => {}) {
  // promisify XHR
  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    // eslint-disable-line promise/avoid-new
    const xhr = new XMLHttpRequest();

    // attach listeners before opening!
    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        const percentage = Math.round((e.loaded / e.total) * 100);
        onProgressCallback(percentage);
      }
    };

    xhr.onload = () => {
      try {
        const { status } = xhr;
        const data = parseXHRResponse(xhr);
        resolve({ status, data });
      } catch (err) {
        console.error(err); // eslint-disable-line no-console
        reject(xhr);
      }
    };

    xhr.onerror = () => {
      const status = httpStatusCodes.NOT_FOUND;
      const message = 'Network error occurred.';
      reject({ status, message }); // eslint-disable-line prefer-promise-reject-errors
    };

    xhr.open('POST', url, true);

    // add headers, if any
    if (opts.headers) {
      Object.keys(opts.headers).forEach(key => {
        xhr.setRequestHeader(key, opts.headers[key]);
      });
    }

    // set response type, if any
    if (opts.responseType) {
      xhr.responseType = opts.responseType;
    }

    // create request body by merging file with specified existing body
    const data = {
      [apiFieldName]: file,
      ...opts.body,
    };
    const body = addFormData(data);

    // send request
    xhr.send(body);
  });
}

export function fetchSliceImages(sliceViewID, start, numSlices, token) {
  const url = urls.getSliceImages(sliceViewID, start, numSlices);
  const header = {
    method: 'GET',
    headers: {
      Authorization: `JWT ${token}`,
      'Content-Type': `multipart/form-data; boundary=--${urls.MULTIPART_FORM_BOUNDARY}`,
    },
  };

  return fetch(fullUrl(url), header)
    .then(res => res.formData())
    .then(formData => {
      const formParts = [];

      for (let i = 0; i < numSlices; i += 1) {
        formParts.push(formData.get(String(i)));
      }

      return formParts;
    });
}

export function fetchMask(segID) {
  const token = localStorage.getItem('token');
  const [headerURL, rawURL] = getMaskMHDURL(segID);

  const headerPromise = fetch(fullUrl(headerURL), initialize(token, 'GET'))
    .then(res => res.text())
    .then(str => {
      const headerData = {};

      str.split('\n').forEach(line => {
        if (!line.length) {
          return;
        }

        const [key, value] = line.split(' = ');
        switch (key) {
          case 'DimSize':
            headerData.size = value;
            break;
          case 'ElementSpacing':
            headerData.spacing = value;
            break;
          case 'Offset':
            headerData.origin = value;
            break;
          default:
        }
      });

      const origin = new Vector3(...headerData.origin.split(' ').map(Number));
      const size = new Vector3(...headerData.size.split(' ').map(Number));
      const spacing = new Vector3(...headerData.spacing.split(' ').map(Number));
      const position = size
        .clone()
        .multiplyScalar(0.5)
        .multiply(spacing)
        .add(origin);

      headerData.origin = position.toArray().join(' ');

      return headerData;
    });

  const rawPromise = fetch(fullUrl(rawURL), initialize(token, 'GET')).then(res => {
    if (!res.ok) {
      throw new Error();
    }

    return res.arrayBuffer();
  });

  return Promise.all([headerPromise, rawPromise])
    .then(([headerData, rawData]) => {
      const bufGeo = new VFFLoader().parseVoxelData(new Uint8Array(rawData), 0, headerData);

      return bufGeo;
    })
    .catch(async () => {
      const res = await fetch(fullUrl(getMaskVFFURL(segID)), initialize(token, 'GET'));
      const arrayBuffer = await res.arrayBuffer();

      const bufGeo = new VFFLoader().parse(arrayBuffer);

      return bufGeo;
    });
}
