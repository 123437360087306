import saveCenterline from './saveCenterline';

import { getSelectedSegID } from '../../../viewer/selectors';
import verifySegmentationRedux from '../../../segmentations/verifySegmentation';
import store from '../../../../store';

export default function verifySegmentation() {
  return (notify, getSceneState, callSceneAction) => {
    const { centerline, centerlineChanges } = getSceneState();
    centerlineChanges.states[centerlineChanges.idx] = centerline.serialize({
      computeCarinaRegions: true,
      includeHiddenBranches: false,
    });

    const segID = getSelectedSegID(store.getState().viewer);

    return callSceneAction(saveCenterline(segID)).then(() =>
      store.dispatch(verifySegmentationRedux(segID)),
    );
  };
}
