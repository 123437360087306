import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ModalStyled = props => {
  const { show, backdrop, children, ...rest } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <ModalWrapper show={show} backdrop={backdrop} {...rest}>
      {children}
    </ModalWrapper>
  );
};

ModalStyled.defaultProps = {
  show: false,
  backdrop: 'static',
};

ModalStyled.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired,
  show: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
};

export default ModalStyled;

const ModalWrapper = styled(Modal)`
  .modal-content {
    ${props => props.theme.modals.content}
  }
`;

const ModalBody = styled(Modal.Body)`
  padding: 20px 40px;
`;

const ModalHeader = styled(Modal.Header)`
  padding: 20px 40px;
  ${props => props.theme.modals.header}
`;

const ModalFooter = styled(Modal.Footer)`
  padding: 20px 40px;
  ${props => props.theme.modals.footer}
`;

ModalStyled.Header = ModalHeader;
ModalStyled.Body = ModalBody;
ModalStyled.Footer = ModalFooter;
