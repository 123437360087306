import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import parseISO from 'date-fns/parseISO';
import compareDesc from 'date-fns/compareDesc';

import { getVolsByScanID } from '../../../modules/volume3Ds/selectors';
import { getSegsByVolID } from '../../../modules/segmentations/selectors';
import setSegID from '../../../modules/viewer/setSegID';
import setVolID from '../../../modules/viewer/setVolID';

import { SEG_STATUS } from '../../../settings/segmentations';
import { debounced, formatDate } from '../../../utils/general';

import segHistory from '../../../images/seg-history.svg';

export default function SegHistoryDropdown({ scan }) {
  const containerRef = useRef();
  const [width, setWidth] = useState(0);
  useEffect(() => {
    // eslint-disable-next-line no-new
    new ResizeSensor(
      containerRef.current,
      debounced(100, () => {
        const { offsetWidth } = containerRef.current;
        setWidth(offsetWidth);
      }),
    );
  }, []);

  const reduxProps = useSelector(({ volume3Ds, segmentations }) => ({
    volume3Ds,
    segmentations,
  }));

  const scanVols = getVolsByScanID(reduxProps.volume3Ds, scan.id)?.toJS();

  let allSegsForScan = [];
  scanVols.forEach(({ id: volID }) => {
    const volSegs = getSegsByVolID(reduxProps.segmentations, volID)?.toJS();
    if (volSegs) {
      allSegsForScan = allSegsForScan.concat(volSegs);
    }
  });

  // don't show segs that haven't started yet since there's no way to sort them
  // if there's multiple series
  allSegsForScan = allSegsForScan
    .filter(seg => seg.startedAt)
    .sort((a, b) => compareDesc(parseISO(a.startedAt), parseISO(b.startedAt)));

  return (
    <DropdownContainer alignRight>
      <ToggleBtn variant='' ref={containerRef}>
        {width > 150 && width < 230 && <Text>History</Text>}
        {width > 230 && <Text>Segmentation History</Text>}
        <HistoryIcon src={segHistory} showText={width > 150} alt='History' />
      </ToggleBtn>

      <DropdownMenu data-test='seg-history-list'>
        {allSegsForScan.map(seg => (
          <SegItem seg={seg} key={seg.id} />
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
}

SegHistoryDropdown.propTypes = {
  scan: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
};

function DropdownItem({ seg, className }) {
  const dispatch = useDispatch();

  const handleSegSelect = eventKey => {
    const [volID, segID] = eventKey.split('-');
    dispatch(setSegID(segID));
    dispatch(setVolID(volID));
  };

  const statusText = {
    [SEG_STATUS.CREATED]: 'Pending',
    [SEG_STATUS.RUNNING]: 'In Progress',
    [SEG_STATUS.SUCCESS]: 'Success',
    [SEG_STATUS.ERROR]: 'Error',
    [SEG_STATUS.VERIFIED]: 'Verified',
  }[seg.status];

  return (
    <Dropdown.Item
      eventKey={`${seg.volume3DID}-${seg.id}`}
      onSelect={handleSegSelect}
      className={className}
    >
      <SegAlphaNum>{seg.segAlphaNum}</SegAlphaNum>
      <CompletedAt>{formatDate(seg.completedAt, 'yyyy/MM/dd HH:mm')}</CompletedAt>
      <Status>{statusText}</Status>
    </Dropdown.Item>
  );
}

DropdownItem.propTypes = {
  seg: PropTypes.shape({
    id: PropTypes.string,
    volume3DID: PropTypes.string,
    completedAt: PropTypes.string,
    status: PropTypes.string,
    segAlphaNum: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};

DropdownItem.defaultProps = {
  className: '',
};

const DropdownContainer = styled(Dropdown)`
  flex: 1 0 75px;
  max-width: 350px;
`;

const ToggleBtn = styled(Dropdown.Toggle)`
  width: 100%;
  height: 100%;

  background-color: #1d1d1d;
  border: 1px solid #494949;
  border-radius: 6px;
  box-shadow: none !important;

  color: #ffffff;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  /* Caret */
  ::after {
    position: absolute;
    right: 15px;
  }

  &&&:hover,
  &&&:active {
    background-color: #2d2d2d;
    border: 1px solid #494949;
    color: #ffffff;
  }
`;

const Text = styled.div`
  color: #ffffff;
`;

const HistoryIcon = styled.img`
  margin-right: 3px; /* offset icon so it looks centered */

  ${({ showText }) => (showText ? { position: 'absolute', left: '15px' } : {})}
`;

const DropdownMenu = styled(Dropdown.Menu)`
  background-color: #1d1d1d;
  border-color: #494949;
  z-index: 1100;

  margin-top: 8px;
  padding: 10px;

  max-height: 245px;
  overflow: auto;

  ::-webkit-scrollbar {
    background-color: #a0a0a0;
    border-radius: 4px;
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: #2f2e2e;
    box-shadow: inset 0 0 10px 10px transparent;
    border: solid 4px transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    box-shadow: inset 0 0 10px 10px #a0a0a0;
    border: solid 7px #a0a0a0;
    background-clip: padding-box;
    border-radius: 4px;
  }
`;

const SegItem = styled(DropdownItem)`
  width: 375px;
  height: 36px;

  border-radius: 4px;
  font-size: 14px;

  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: space-between;

  :hover {
    background-color: #343434;
    color: #ffffff;
  }
`;

const SegAlphaNum = styled.div`
  width: 50px;
`;

const CompletedAt = styled.div``;

const Status = styled.div`
  width: 80px;
  display: flex;
  justify-content: flex-end;
`;
