import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';

import ContainerOverlay from '../../modals/ContainerOverlay';
import LoadingThreeDots from '../../loading/LoadingThreeDots';

import { SEG_STATUS } from '../../../settings/segmentations';

import IconError from '../../../images/statuses/icon-error.svg';
import logoWithWordmark from '../../../images/logo-teal-w-wordmark.svg';

import { getIsLoadingAirway } from '../../../modules/viewer/selectors';

const MIN_LOAD_TIME_MS = 1000;

export default function ViewerOverlay({ segStatus, ...rest }) {
  const [isLoadTimeElapsed, setIsLoadTimeElapsed] = useState(true);
  const isLoadingAirway = useSelector(({ viewer }) => getIsLoadingAirway(viewer));
  const showAirwayLoading = isLoadingAirway || !isLoadTimeElapsed;

  const prevTimestampRef = useRef(0);
  const prevAirwayLoadingState = useRef(isLoadingAirway);

  useEffect(() => {
    if (isLoadingAirway) {
      prevTimestampRef.current = Date.now();
      setIsLoadTimeElapsed(false);
    } else if (
      prevAirwayLoadingState.current &&
      Date.now() - prevTimestampRef.current >= MIN_LOAD_TIME_MS
    ) {
      setIsLoadTimeElapsed(true);
    } else if (
      prevAirwayLoadingState.current &&
      Date.now() - prevTimestampRef.current < MIN_LOAD_TIME_MS
    ) {
      setTimeout(() => {
        setIsLoadTimeElapsed(true);
      }, MIN_LOAD_TIME_MS - (Date.now() - prevTimestampRef.current) + 10);
    }

    prevAirwayLoadingState.current = isLoadingAirway;
  }, [isLoadingAirway]);

  if (!showAirwayLoading && segStatus !== SEG_STATUS.RUNNING && segStatus !== SEG_STATUS.ERROR) {
    return null;
  }

  let content;

  if (showAirwayLoading) {
    content = (
      <>
        <AirwayLoadingContainer>
          <img src={logoWithWordmark} alt='VisionAir Solutions' />
          <LoadingThreeDots color='#ffffff' />
        </AirwayLoadingContainer>
      </>
    );
  } else if (segStatus === SEG_STATUS.RUNNING) {
    content = (
      <>
        <IconContainer>
          <Spinner animation='border' variant='light' />
        </IconContainer>
        Segmenting Airway...
      </>
    );
  } else if (segStatus === SEG_STATUS.ERROR) {
    content = (
      <>
        <IconContainer>
          <ErrorIcon src={IconError} alt='Error' />
        </IconContainer>
        Error
      </>
    );
  }

  return (
    <Overlay
      status={segStatus}
      backdropClassName='backdrop-viewer'
      autoFocus={false}
      enforceFocus={false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {content}
    </Overlay>
  );
}

ViewerOverlay.propTypes = {
  segStatus: PropTypes.string.isRequired,
};

const Overlay = styled(ContainerOverlay)`
  .modal-content {
    width: 275px;
    height: 65px;

    border-radius: 11px;

    ${({ status }) =>
      ({
        [SEG_STATUS.RUNNING]: {
          'background-color': '#0f43a2',
        },
        [SEG_STATUS.ERROR]: {
          'background-color': '#752424',
        },
      }[status])}
  }

  .modal-body {
    display: flex;
    align-items: center;

    color: #ffffff;
    font-size: 16px;
  }
`;

const IconContainer = styled.div`
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
`;

const ErrorIcon = styled.img`
  height: 20px;
`;

const AirwayLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: #141414;

  padding: 10px;
  border-radius: 4px;

  position: relative;
  bottom: 20px;
  right: 20px;
`;
