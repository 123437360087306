import * as types from '../actions';
import { getSegmentationsByVolURL } from '../../settings/api';
import { apiFetch } from '../../utils/requests';
import { getToken } from '../auth/selectors';
import { getSeriesAlphaByID } from '../volume3Ds/selectors';

export function addVolSegmentations(segmentations, seriesAlpha) {
  return {
    type: types.ADD_SEGMENTATIONS_BY_VOLUME_3D,
    segmentations,
    seriesAlpha,
  };
}

export default function fetchSegmentationsByVol(volID) {
  return (dispatch, getState) => {
    const { auth, volume3Ds } = getState();
    const token = getToken(auth);
    const seriesAlpha = getSeriesAlphaByID(volume3Ds, volID);

    return apiFetch(getSegmentationsByVolURL(volID), token, 'GET').then(segs => {
      dispatch(addVolSegmentations(segs, seriesAlpha));
      return segs;
    });
  };
}
