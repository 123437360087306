import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ButtonDefault from '../../buttons/ButtonDefault';
import ScanRejectModal from '../../modals/ScanRejectModal';

import { SCAN_STATUS } from '../../../settings/segmentations';

export default function RejectScanBtn({ scanStatus }) {
  const [showModal, setShowModal] = useState(false);

  const handleClose = useCallback(() => setShowModal(false), []);

  return (
    <>
      <RejectBtn
        onClick={() => setShowModal(true)}
        disabled={scanStatus === SCAN_STATUS.VERIFIED || scanStatus === SCAN_STATUS.REJECTED}
      >
        Reject Scan
      </RejectBtn>
      <ScanRejectModal show={showModal} onClose={handleClose} />
    </>
  );
}

RejectScanBtn.propTypes = {
  scanStatus: PropTypes.string.isRequired,
};

const RejectBtn = styled(ButtonDefault)`
  width: 160px;
  background-image: linear-gradient(to top, #4a0000, #930202);

  :hover {
    background-image: none;
    border: solid 1px #8f0000;
    background-color: #930202;
  }

  :active {
    background-image: none;
    border: solid 1px #930202;
    background-color: #4b0000;
  }

  :disabled {
    background-image: none;
    border: solid 1px #8f0000;
    background-color: #930202;
    opacity: 0.5;
  }
`;
