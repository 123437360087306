import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import Modal from '../../modals/ModalStyled';
import ButtonDefault from '../../buttons/ButtonDefault';

import clearSegmentation from '../../../modules/segmentations/clearSegmentation';
import { getSelectedSegID } from '../../../modules/viewer/selectors';

export default function StartOverBtn({ disabled, className }) {
  const [showModal, setShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const segID = useSelector(({ viewer }) => getSelectedSegID(viewer));
  const dispatch = useDispatch();

  const handleStartOver = () => {
    setIsSubmitting(true);
    dispatch(clearSegmentation(segID))
      .then(() => {
        setIsSubmitting(false);
        setShowModal(false);
      })
      .catch(err => {
        setIsSubmitting(false);
        // eslint-disable-next-line no-console
        console.error(err);
        setShowModal(false);
      });
  };

  return (
    <>
      <Button disabled={disabled} className={className} onClick={() => setShowModal(true)}>
        Start Over
      </Button>
      <Modal show={showModal} centered backdropClassName='modal-backdrop-blur'>
        <Modal.Header>Start Over?</Modal.Header>
        <Modal.Body>Are you sure you want to remove all seed points and start over?</Modal.Body>
        <Modal.Footer>
          <CancelBtn disabled={isSubmitting} onClick={() => setShowModal(false)}>
            Cancel
          </CancelBtn>
          <ConfirmBtn disabled={isSubmitting} onClick={handleStartOver}>
            Start Over
          </ConfirmBtn>
        </Modal.Footer>
      </Modal>
    </>
  );
}

StartOverBtn.propTypes = {
  disabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

StartOverBtn.defaultProps = {
  className: '',
};

const Button = styled(ButtonDefault)`
  padding: 0 20px;
  height: 40px;
  border-radius: 6px;
  background-color: #186655;
  border: none;
  color: white;
`;

const CancelBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #969696;
  margin-right: 20px !important;
`;

const ConfirmBtn = styled(ButtonDefault)`
  width: 190px;
  background-color: #aa0014;
`;
