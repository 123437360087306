import React, { useState, useCallback, useRef, useEffect } from 'react';
import { SphereBufferGeometry, Mesh, MeshBasicMaterial } from 'three';
import {
  useCanvasMouseEvent,
  useSceneDispatch,
  useSceneSelector,
} from '@visionair/scene-state-3js';

import visualizeCenterline from '../../../modules/scene/actions/airwayView/visualizeCenterline';
import { CARINA_DEFAULT_COLOR } from '../../../settings/centerline';
import Instructions from './Instructions';

import moveIconActive from '../../../images/centerline_tools/move_carina_active.svg';
import { isClick } from '../../../modules/scene/selectors';

const SELECT_CARINA = 0;
const MOVE_CARINA = 1;
const CONFIRM = 2;

const stepToText = {
  [SELECT_CARINA]: 'Left-Click to select a carina point.',
  [MOVE_CARINA]: 'Position the new carina location and left-click to place.',
  [CONFIRM]: 'Confirm new carina position.',
};

const HOVER_CARINA_COLOR = 0xff0000;
const MOUSE_SPHERE_COLOR = 0xffff00;

export default function MoveCarinaTool({ onFinish }) {
  const selectedBranchRef = useRef();
  const mouseSphereRef = useRef();
  const prevSelectedCarinaRef = useRef();
  const [step, setStep] = useState(SELECT_CARINA);
  const scene = useSceneSelector(state => state.scene);

  const dispatch = useSceneDispatch();

  useCanvasMouseEvent(
    'pointermove',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (step === SELECT_CARINA) {
          const { branch } = sceneState.centerline.getClosestCarinaPointToRay(raycaster.ray);
          const carina = sceneState.scene.getObjectByName(branch.carinaName);
          carina.material.color.set(HOVER_CARINA_COLOR);

          if (prevSelectedCarinaRef.current && carina !== prevSelectedCarinaRef.current) {
            prevSelectedCarinaRef.current.material.color.set(CARINA_DEFAULT_COLOR);
          }

          prevSelectedCarinaRef.current = carina;
        } else if (step === MOVE_CARINA) {
          const { point } = sceneState.centerline.getClosestPointToRay(raycaster.ray);
          mouseSphereRef.current.position.copy(point);
        }
      },
      [step],
    ),
  );

  useCanvasMouseEvent(
    'pointerup',
    useCallback(
      (evt, sceneState, raycaster) => {
        if (!isClick(sceneState, evt) || step === CONFIRM) {
          return;
        }

        if (step === SELECT_CARINA) {
          const { branch } = sceneState.centerline.getClosestCarinaPointToRay(raycaster.ray);
          selectedBranchRef.current = branch;

          const mouseSphere = new Mesh(
            new SphereBufferGeometry(1, 32, 32),
            new MeshBasicMaterial({ color: MOUSE_SPHERE_COLOR }),
          );
          mouseSphereRef.current = mouseSphere;

          sceneState.scene.add(mouseSphere);

          setStep(MOVE_CARINA);
        } else if (step === MOVE_CARINA) {
          const { point } = sceneState.centerline.getClosestPointToRay(raycaster.ray);
          selectedBranchRef.current.moveCarina(point);
          selectedBranchRef.current.samplePointsAtDist(1);
          selectedBranchRef.current.children.forEach(b => {
            b.samplePointsAtDist(1);
          });
          selectedBranchRef.current.findCarinaRegionBorders();
          selectedBranchRef.current.children.forEach(b => {
            b.fitToCenter();
          });

          dispatch(visualizeCenterline());

          sceneState.scene.remove(mouseSphereRef.current);

          setStep(CONFIRM);
          onFinish();
        }
      },
      [onFinish, step, dispatch],
    ),
  );

  useEffect(
    () => () => {
      if (mouseSphereRef.current) {
        scene.remove(mouseSphereRef.current);
      }
      dispatch(visualizeCenterline());
    },
    [dispatch, scene],
  );

  return (
    <Instructions>
      <img src={moveIconActive} alt='' /> {stepToText[step]}
    </Instructions>
  );
}
