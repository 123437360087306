import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from 'react-bootstrap/Button';
import { MdInfo } from 'react-icons/md';

import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { getScanByID } from '../../../modules/scans/selectors';
import { getVolByID } from '../../../modules/volume3Ds/selectors';

import AirwayLogo from '../../AirwayLogo';
import SeedListBtn from './SeedListBtn';
import StartOverBtn from './StartOverBtn';
import ClearPendingSeedsBtn from './ClearPendingSeedsBtn';
import SeriesSegSelect from './SeriesSegSelect';

import {
  getSelectedSeg,
  getShowSeedList,
  getShowSliceViews,
  getShowScanInfoTable,
} from '../../../modules/viewer/selectors';
import setShowScanInfoTable from '../../../modules/viewer/setShowScanInfoTable';

import { SCAN_STATUS, SEG_STATUS } from '../../../settings/segmentations';

import colors from '../../../stylesheets/theme/colors';
import { formatDate } from '../../../utils/general';

import seedIcon from '../../../images/seed-list.svg';
import minIcon from '../../../images/minimize.svg';
import setShowSeedList from '../../../modules/viewer/setShowSeedList';
import AddSeedBtn from '../slice-viewers/AddSeedBtn';
import SensitivitySlider from './SensitivitySlider';

export default function SeedPanel({ volID, className }) {
  const { scanID } = useParams();
  const { scan, vol3D, seg, showSeedList, showSliceViews, showScanInfoTable } = useSelectorToJS(
    ({ scans, viewer, segmentations, volume3Ds }) => ({
      scan: getScanByID(scans, scanID),
      vol3D: getVolByID(volume3Ds, volID),
      seg: getSelectedSeg(viewer, segmentations),
      showSeedList: getShowSeedList(viewer),
      showSliceViews: getShowSliceViews(viewer),
      showScanInfoTable: getShowScanInfoTable(viewer),
    }),
  );

  const dispatch = useDispatch();

  if (!showSeedList) {
    return null;
  }

  const completedSeeds = seg.seedPoints.filter(seed => !seed.pending).sort((a, b) => a.id - b.id);
  const pendingSeeds = seg.seedPoints.filter(seed => seed.pending).sort((a, b) => a.id - b.id);

  return (
    <Container className={className} showBorder={!showSliceViews}>
      <Header>
        <div>
          <img src={seedIcon} alt='' />
          Seeding
        </div>
        <button
          type='button'
          onClick={() => dispatch(setShowSeedList(false))}
          data-test='min-seed-btn'
        >
          <img src={minIcon} alt='Minimize' />
        </button>
      </Header>
      <ScanInfo>
        <ScanInfoTable>
          <tbody>
            <tr>
              <th style={{ width: '75px' }}>CT</th>
              <th style={{ width: '115px' }}>Series</th>
              <th>Seg ID</th>
            </tr>
            <tr>
              <td data-test='sidebar-scanNum'>{scan.scanNum}</td>
              <td data-test='sidebar-seriesNumber'>{vol3D.seriesNumber}</td>
              <td data-test='sidebar-segID'>{seg.id}</td>
            </tr>
            <tr>
              <th>Uploaded</th>
            </tr>
            <tr>
              <td colSpan='3'>{formatDate(scan.uploaded_at)}</td>
            </tr>
          </tbody>
        </ScanInfoTable>
        <ScanInfoButton
          variant='link'
          onClick={() => dispatch(setShowScanInfoTable(!showScanInfoTable))}
          data-test='view-scan-info-button'
        >
          <MdInfo size={22} color='#23D3CD' />
        </ScanInfoButton>
      </ScanInfo>
      <BtnContainer>
        <StartOverButton disabled={scan.status !== SCAN_STATUS.UPLOADED} />
        <SeriesSegButton />
      </BtnContainer>
      <SensitivitySlider segID={seg.id} />
      {Boolean(completedSeeds.length) && (
        <CurrentSeedsHeader>
          <SeedStatusContainer>
            <StyledAirwayLogo color={colors.colorSeedCompleted} />
            <div>Current Segmentation</div>
          </SeedStatusContainer>
        </CurrentSeedsHeader>
      )}
      {completedSeeds.map((seed, i) => (
        <SeedListBtn seed={seed} scanStatus={scan.status} key={seed.id}>
          Seed&nbsp;
          {i + 1}
        </SeedListBtn>
      ))}
      {Boolean(pendingSeeds.length) && (
        <PendingSeedsHeader>
          <SeedStatusContainer>
            <StyledAirwayLogo color={colors.colorSeedPending} />
            <div>Awaiting Segmentation</div>
          </SeedStatusContainer>
          {scan.status === SCAN_STATUS.UPLOADED && <ClearPendingSeedsBtn />}
        </PendingSeedsHeader>
      )}
      {pendingSeeds.map((seed, i) => (
        <SeedListBtn seed={seed} scanStatus={scan.status} key={seed.id}>
          Seed&nbsp;
          {completedSeeds.length + i + 1}
        </SeedListBtn>
      ))}
      {scan.status === SCAN_STATUS.UPLOADED && seg.status !== SEG_STATUS.RUNNING && <AddSeed />}
    </Container>
  );
}

const Container = styled.div`
  background: #1d1d1d;
  position: relative;

  ${props =>
    props.showBorder &&
    `
  border-left: 5px solid black;
  border-right: 5px solid black;
  `}
`;

const AddSeed = styled(AddSeedBtn)`
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: calc(100% - 40px);
`;

const Header = styled.div`
  height: 48px;
  background-image: linear-gradient(to bottom, #414141, #0d0d0d);
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 12px;
  font-weight: bold;

  button {
    border: none;
    background: none;
    outline: none;
  }
`;

const ScanInfo = styled.div`
  color: white;
  padding: 20px;
  display: flex;
`;

const ScanInfoTable = styled.table`
  width: 100%;

  th {
    font-size: 10px;
    font-weight: normal;
    color: #6a6a6a;
  }

  td {
    font-size: 12px;
    font-weight: bold;
  }
`;

const ScanInfoButton = styled(Button)`
  margin-top: 2px;
  padding: 0;
  height: 100%;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 80px;
  border-top: 5px solid #393939;
`;

const StartOverButton = styled(StartOverBtn)`
  flex: 1 1 50px;
  margin-right: 7.5px;
`;

const SeriesSegButton = styled(SeriesSegSelect)`
  flex: 1 1 50px;
  margin-left: 7.5px;
`;

const CurrentSeedsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  color: ${props => props.theme.colors.colorSeedCompleted};
  background-image: linear-gradient(to bottom, #404040, #1a1a1a);
`;

const PendingSeedsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding: 0 20px;
  color: ${props => props.theme.colors.colorSeedPending};
  background-image: linear-gradient(to bottom, #404040, #1a1a1a);
`;

const SeedStatusContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledAirwayLogo = styled(AirwayLogo)`
  margin-right: 10px;
`;
