/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTextMask from 'react-text-mask';

const MaskedInput = ({ inputRef, ...rest }) => (
  <ReactTextMask
    {...rest}
    ref={ref => {
      if (inputRef) {
        inputRef(ref ? ref.inputElement : null);
      }
    }}
  />
);

MaskedInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default MaskedInput;

export const PhoneInput = ({ ...props }) => (
  <MaskedInput
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    keepCharPositions
    {...props}
  />
);

export const ZipCodeInput = ({ ...props }) => (
  <MaskedInput mask={[/\d/, /\d/, /\d/, /\d/, /\d/]} guide={false} keepCharPositions {...props} />
);

export const DateMask = ({ ...props }) => (
  <MaskedInput
    mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
    guide={false}
    keepCharPositions
    {...props}
  />
);

export const CoreNumMask = ({ ...props }) => (
  <MaskedInput mask={[/\d/, /\d/]} guide={false} keepCharPositions {...props} />
);

export const ScanNumMask = ({ ...props }) => (
  <MaskedInput
    mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
    guide={false}
    keepCharPositions
    {...props}
  />
);
