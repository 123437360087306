import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AmbientLight, Box3, Vector3, DirectionalLight } from 'three';
import ResizeSensor from 'css-element-queries/src/ResizeSensor';
import Spinner from 'react-bootstrap/Spinner';
import {
  Provider as SceneProvider,
  useSceneDispatch,
  useRenderEffect,
  resizeCanvas,
  useSceneSetup,
  useSceneEffect,
  initializeControls,
} from '@visionair/scene-state-3js';

import ViewerOverlay from './ViewerOverlay';
import CenterlineTools from './CenterlineTools';

import { getAirwayURL } from '../../../settings/api';
import { CANVAS_RESIZE_DEBOUNCE_MS } from '../../../settings/viewer';
import { debounced } from '../../../utils/general';

import loadAirwaySTL from '../../../modules/scene/actions/airwayView/loadAirwaySTL';
import loadCenterline from '../../../modules/scene/actions/airwayView/loadCenterline';
import removeAirway from '../../../modules/scene/actions/airwayView/removeAirway';
import removeSegBB from '../../../modules/scene/actions/airwayView/removeSegBB';
import initializeSlicePlanes from '../../../modules/scene/actions/airwayView/initializeSlicePlanes';
import addSegBB from '../../../modules/scene/actions/airwayView/addSegBB';

import { showNotificationError } from '../../../modules/notifications/showNotification';
import setIsLoadingAirway from '../../../modules/viewer/setIsLoadingAirway';
import { getIsLoadingCenterline } from '../../../modules/viewer/selectors';
import useSelectorToJS from '../../../hooks/useSelectorToJS';
import { getSegByID } from '../../../modules/segmentations/selectors';
import CrossSectionViewer from '../cross-section-viewer/CrossSectionViewer';
import { CROSS_SECTION_VIEWER_NAME, getAirwaySTL } from '../../../modules/scene/selectors';
import AirwayViewerHeader from './AirwayViewerHeader';
import { SEG_STATUS } from '../../../settings/segmentations';
import { CENTERLINE_STATUS_SUCCESS } from '../../../settings/centerline';
import removeCenterline from '../../../modules/scene/actions/airwayView/removeCenterline';

export function AirwayViewerComponent({ canvasName, segIDToFetch, seg }) {
  const canvasContainerRef = useRef();
  const sceneDispatch = useSceneDispatch();
  const reduxDispatch = useDispatch();
  const history = useHistory();

  useSceneSetup(state => {
    state.scene.add(new AmbientLight(0x505050));

    sceneDispatch(initializeControls(canvasContainerRef.current));
    // eslint-disable-next-line no-param-reassign
    state.controls.panSpeed = 4;

    // eslint-disable-next-line no-new
    new ResizeSensor(
      canvasContainerRef.current,
      debounced(CANVAS_RESIZE_DEBOUNCE_MS, () => {
        const { clientWidth: width, clientHeight: height } = canvasContainerRef.current;
        sceneDispatch(resizeCanvas(Math.floor(width), Math.floor(height)));
      }),
    );
  });

  useRenderEffect(state => {
    if (state.controls) {
      state.controls.update();
    }

    state.light.position.copy(state.camera.position);
  });

  const isLoadingCenterline = useSelector(({ viewer }) => getIsLoadingCenterline(viewer));
  const [bbMin, bbMax] = useSelectorToJS(({ segmentations }) => [
    getSegByID(segmentations, seg.id).get('bbMin'),
    getSegByID(segmentations, seg.id).get('bbMax'),
  ]);

  useEffect(() => {
    sceneDispatch(removeSegBB());

    if (!bbMin || !bbMax) {
      return;
    }

    const bb = new Box3(
      new Vector3(bbMin.X, bbMin.Y, bbMin.Z),
      new Vector3(bbMax.X, bbMax.Y, bbMax.Z),
    );
    sceneDispatch(addSegBB(bb));
  }, [bbMin, bbMax, sceneDispatch]);

  useEffect(() => {
    sceneDispatch(removeAirway());
    sceneDispatch(removeCenterline());
    if (!segIDToFetch) {
      return;
    }

    reduxDispatch(setIsLoadingAirway(true));

    const airwayURL = getAirwayURL(segIDToFetch);
    sceneDispatch(loadAirwaySTL(airwayURL))
      .then(() => {
        sceneDispatch(initializeSlicePlanes());

        reduxDispatch(setIsLoadingAirway(false));
      })
      .then(() => {
        sceneDispatch(loadCenterline(segIDToFetch));
      })
      .catch(err => {
        history.push(`/scans`);
        reduxDispatch(
          showNotificationError('An unexpected error occurred while fetching airway surface.'),
        );
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, [segIDToFetch, history, sceneDispatch, reduxDispatch]);

  useSceneEffect(
    state => {
      const airwayLoaded = Boolean(getAirwaySTL(state));
      const centerlineLoaded = Boolean(state.centerline);

      if (airwayLoaded && !centerlineLoaded && seg.centerlineStatus === CENTERLINE_STATUS_SUCCESS) {
        sceneDispatch(loadCenterline(segIDToFetch));
      }
    },
    [segIDToFetch, seg.centerlineStatus, sceneDispatch],
  );

  return (
    <>
      <AirwayViewerHeader seg={seg} />
      <CrossSectionViewer canvasName={CROSS_SECTION_VIEWER_NAME} />
      {seg.status === SEG_STATUS.SUCCESS && <CenterlineTools />}
      <CanvasContainer ref={canvasContainerRef}>
        {isLoadingCenterline && (
          <CenterlineLoadingIndicator>
            Processing Centerline
            <Spinner animation='border' variant='light' />
          </CenterlineLoadingIndicator>
        )}
        <Canvas className={canvasName} data-test='airway-canvas' />
        <ViewerOverlay
          segStatus={seg.status}
          containerRef={canvasContainerRef}
          show
          centered
          animation={false}
        />
      </CanvasContainer>
    </>
  );
}

AirwayViewerComponent.propTypes = {
  segIDToFetch: PropTypes.string,
  seg: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
};

AirwayViewerComponent.defaultProps = {
  segIDToFetch: null,
};

const scenePropsFactory = () => ({
  light: new DirectionalLight(0xffffff, 0.8),
  isAirwayViewer: true,
  selectedBranch: null,
  tempBranch: null,
  centerline: null,
  centerlineChanges: {
    states: [],
    idx: 0,
    needsUpdate: false,
  },
});

export default styled(SceneProvider(AirwayViewerComponent, scenePropsFactory))`
  flex: 2 2 100px;
  position: relative;
`;

const CanvasContainer = styled.div`
  height: calc(100% - 48px);
  background: #2b2b2b;
  position: relative;
  min-width: 640px;
  min-height: 652px;
`;

const Canvas = styled.canvas`
  position: absolute;
`;

const CenterlineLoadingIndicator = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 60px;
  width: 270px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  border-radius: 6px;
  background: #0f43a2;
`;
