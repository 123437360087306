import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { format, parse } from 'date-fns';

import { formatDate } from '../../../utils/general';
import { dicomDateStringToDate, isScanExpired } from '../../../utils/dicom';

import { getSelectedVolID, getSelectedSegID } from '../../../modules/viewer/selectors';
import { getVolsByScanID } from '../../../modules/volume3Ds/selectors';
import { getSegsByVolID } from '../../../modules/segmentations/selectors';
import setSegID from '../../../modules/viewer/setSegID';
import setVolID from '../../../modules/viewer/setVolID';

export default function SeriesSegSelect({ className }) {
  const { scanID } = useParams();

  const dispatch = useDispatch();

  const reduxProps = useSelector(({ volume3Ds, segmentations, viewer }) => ({
    volume3Ds,
    segmentations,
    selectedVolID: getSelectedVolID(viewer, segmentations),
    selectedSegID: getSelectedSegID(viewer, segmentations),
  }));

  if (!reduxProps.selectedVolID) {
    return null;
  }

  const scanVols = getVolsByScanID(reduxProps.volume3Ds, scanID)?.toJS();

  const volSegMap = {};
  scanVols.forEach(({ id }) => {
    volSegMap[id] = getSegsByVolID(reduxProps.segmentations, id)
      ?.toJS()
      .sort((a, b) =>
        Number(a.segAlphaNum.split('-')[1]) > Number(b.segAlphaNum.split('-')[1]) ? -1 : 1,
      );
  });

  const selectedVol = scanVols.find(({ id }) => id === reduxProps.selectedVolID);

  const handleSegSelect = eventKey => {
    const [volID, segID] = eventKey.split('-');
    dispatch(setSegID(segID));
    dispatch(setVolID(volID));
  };

  return (
    <>
      <SeriesBtn
        drop='right'
        title={`Series ${selectedVol.seriesAlpha}`}
        className={className}
        data-test='series-dropdown-toggle'
      >
        {scanVols.map(vol => {
          const scanDate = dicomDateStringToDate(vol.scanDate);
          const expiredMessage = isScanExpired(scanDate) ? 'Expired' : '';
          const scanDateText = vol.scanDate
            ? `Acquired: ${format(parse(vol.scanDate, 'yyyyMMdd', new Date()), 'yyyy/MM/dd')}`
            : '';

          return (
            <SeriesItem
              key={vol.id}
              drop='right'
              seriesnum={`Series ${vol.seriesAlpha}`}
              name={`Series ${vol.seriesAlpha}`}
              scandate={scanDateText}
              title={expiredMessage}
            >
              {volSegMap[vol.id].map(seg => (
                <SegItem
                  key={seg.id}
                  eventKey={`${seg.volume3DID}-${seg.id}`}
                  onSelect={handleSegSelect}
                >
                  {seg.segAlphaNum}
                  <SegDate>{formatDate(seg.completedAt)}</SegDate>
                </SegItem>
              ))}
            </SeriesItem>
          );
        })}
      </SeriesBtn>
    </>
  );
}

const SegDate = styled.span`
  margin-left: 30px;
  font-size: 12px;
  font-weight: normal;
`;

const SeriesBtn = styled(DropdownButton)`
  .dropdown-menu.show {
    background: #1d1d1d;
    border: solid 1px #4e4e4e;
    border-radius: 6px;
    padding: 10px 10px 6px 10px;
    left: 6px !important;
    z-index: 2000;
  }

  && button {
    width: 100%;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #1d1d1d !important;
    border: 1px solid #494949;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    padding: 0 20px;

    :hover {
      background: #393939 !important;
      border: 1px solid #494949;
    }

    :active {
      background: #393939 !important;
      border: 2px solid #0077ff;
    }
  }
`;

const SeriesItem = styled(DropdownButton)`
  .dropdown-menu.show {
    left: 18px !important;
    top: -10px !important;
    max-height: 570px;
    overflow: auto;
  }

  && button {
    margin-bottom: 4px;
    width: 300px;
    font-size: 12px;
    font-weight: 500;
    color: red;

    :active {
      background: #393939 !important;
    }

    :focus {
      background: #393939 !important;
    }

    ::before {
      content: "${props => props.seriesnum}";
      font-size: 14px;
      font-weight: bold;
      color: white;
    }

    ::after {
      content: "${props => props.scandate}";
      color: white;
      border: none !important;
    }
  }
`;

const SegItem = styled(Dropdown.Item)`
  color: white;
  border-radius: 4px;
  padding: 0 10px;
  height: 36px;
  width: 240px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  :hover {
    background: #393939;
    color: white;
  }
`;
