import React from 'react';
import { useSceneDispatch, useSceneSelector } from '@visionair/scene-state-3js';

import ToolBtn from './ToolBtn';
import toggleBBIcon from '../../../images/centerline_tools/toggle_bb.svg';
import toggleBBIconActive from '../../../images/centerline_tools/toggle_bb_active.svg';
import { getAxialSlicePlane } from '../../../modules/scene/selectors';
import setSlicePlanesVisibility from '../../../modules/scene/actions/airwayView/setSlicePlanesVisibility';

export default function SliceVisToggleBtn() {
  const dispatch = useSceneDispatch();
  const isVisible = useSceneSelector(state => getAxialSlicePlane(state)?.visible);

  const handleClick = () => {
    dispatch(setSlicePlanesVisibility(!isVisible));
  };

  return (
    <ToolBtn
      tooltip={isVisible ? 'Hide Slice Planes' : 'Show Slice Planes'}
      onClick={handleClick}
      active={isVisible}
      data-test='slice-planes-btn'
    >
      <img src={isVisible ? toggleBBIconActive : toggleBBIcon} alt='' />
    </ToolBtn>
  );
}
