import {
  SphereBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  Color,
  BufferGeometry,
  LineBasicMaterial,
  Line,
  Raycaster,
  ArrowHelper,
  Line3,
  Vector3,
  Euler,
  Ray,
  Plane,
  Math as Math3,
  Float32BufferAttribute,
  Box3,
  Vector2,
} from 'three';
import minimize from 'minimize-golden-section-1d';

export function toPointsBufferGeometry(geo) {
  const numVertices = geo.vertices.length;
  const points = new Float32Array(numVertices * 3);
  for (let i = 0; i < numVertices; i += 1) {
    const pointsIdx = i * 3;
    points[pointsIdx] = geo.vertices[i].x;
    points[pointsIdx + 1] = geo.vertices[i].y;
    points[pointsIdx + 2] = geo.vertices[i].z;
  }

  const bufGeo = new BufferGeometry();
  bufGeo.setAttribute('position', new Float32BufferAttribute(points, 3, false));

  return bufGeo;
}

export function parseAPISeed({ id, position, pending }, sliceView) {
  return {
    id,
    position: new Vector3().fromArray(position),
    projPos: new Vector3(),
    needsUpdate: true,
    sliceIdx: sliceView.calcSliceIdx(new Vector3().fromArray(position)),
    pending,
  };
}

export function getZoomValueToFitObjectBB(object, zoomFactor) {
  const objectBB = new Box3().setFromObject(object);
  object.localToWorld(objectBB); // convert object bounding box points to world coords

  // calc zoom for fitting the object to the screen when using orthographic camera
  const objectWidth = objectBB.max.x - objectBB.min.x;
  const objectHeight = objectBB.max.y - objectBB.min.y;

  const widthRatio = window.innerWidth / objectWidth;
  const heightRatio = window.innerHeight / objectHeight;

  const zoom = Math.min(widthRatio, heightRatio) * zoomFactor;
  const center = new Vector3();
  objectBB.getCenter(center);

  return { zoom, center };
}

export function findPointsCloseTo(point, index, radius, geometry, visualize = false) {
  const positions = geometry.getAttribute('position');

  const closePoint = new Vector3();

  const geom = new SphereBufferGeometry();
  const mat = new MeshBasicMaterial({ color: 0xff0000 });

  const closePoints = [];

  for (let i = 0; i < positions.count; i += 1) {
    closePoint.set(positions.getX(i), positions.getY(i), positions.getZ(i));

    const dist = point.distanceTo(closePoint);
    if (dist < 0.5 && i !== index) {
      closePoints.push(closePoint.clone());
      // console.log(offset(point.clone()));
      // console.log(index);
      // console.log(offset(closePoint.clone()));
      // console.log(i)
      // console.log(' ');

      if (visualize) {
        const { scene } = window;
        const sphere = new Mesh(geom, mat);
        scene.add(sphere);
        sphere.position.copy(closePoint);
      }
    }
  }

  return closePoints;
}

const offsetVec = new Vector3(234.547, 401.047, 176.9);
// const offsetVec = new THREE.Vector3(237.04, 451.04, -1340.2);

export function offset(vec3) {
  return vec3.add(offsetVec);
}

// finds closest points, given 2 arrays of points
// returns: p1 (from points array)
//          p2 (from otherPoints array)
export function findClosestPoints(points, otherPoints) {
  let minDist = Infinity;

  let p1;
  let p2;

  points.forEach((point, idx) => {
    const { closestPoint, index, distance } = findClosestPoint(point, otherPoints);
    if (distance < minDist) {
      minDist = distance;

      p1 = {
        point: point.clone(),
        index: idx,
        distance,
      };

      p2 = {
        point: closestPoint.clone(),
        index,
        distance,
      };
    }
  });

  return { p1, p2 };
}

export function findClosestPoint(point, otherPoints) {
  let minDistSq = Infinity;
  let index;
  let closestPoint;

  otherPoints.forEach((otherPoint, idx) => {
    const distSq = point.distanceToSquared(otherPoint);
    if (distSq < minDistSq) {
      minDistSq = distSq;
      index = idx;
      closestPoint = otherPoint.clone();
    }
  });

  const distance = point.distanceTo(closestPoint);

  return { closestPoint, index, distance };
}

export function createSphere(scene, position, color = randomColor(), radius = 1) {
  const geom = new SphereBufferGeometry(radius, 32, 32);
  const mat = new MeshBasicMaterial({ color, transparent: false, opacity: 0.4 });
  const sphere = new Mesh(geom, mat);
  sphere.position.copy(position);
  scene.add(sphere);

  return sphere;
}

export function computeCentroid(points) {
  let x = 0;
  let y = 0;
  let z = 0;

  points.forEach(point => {
    x += point.x;
    y += point.y;
    z += point.z;
  });

  return new Vector3(x / points.length, y / points.length, z / points.length);
}

export function randomColor() {
  const r = Math.random();
  const g = Math.random();
  const b = Math.random();
  return new Color(r, g, b);
}

export function visualizeCurve(curve, numSegs = 100, color = 0x00ff00) {
  const points = [];
  for (let i = 0; i <= numSegs; i += 1) {
    points.push(curve.getPointAt(i / numSegs));
  }

  const geo = new BufferGeometry().setFromPoints(points);
  const mat = new LineBasicMaterial({ color });

  return new Line(geo, mat);
}

export function getClosestPointOnCurve(curve, searchPoint, minimizeOpts = {}) {
  const dist2 = t => curve.getPoint(t).distanceToSquared(searchPoint);
  const t = minimize(dist2, { lowerBound: 0, upperBound: 1, ...minimizeOpts });

  return { t, point: curve.getPoint(t) };
}

export function getClosestCurvePointToRay(curve, ray, minimizeOpts = {}) {
  const dist2 = t => ray.distanceSqToPoint(curve.getPoint(t));
  const t = minimize(dist2, { lowerBound: 0, upperBound: 1, ...minimizeOpts });

  return { t, point: curve.getPoint(t) };
}

// returns point where line segment points intersect plane
// return undefined if no intersection
export function intersectPlaneWithLineSegments(plane, points) {
  const line = new Line3();
  const intersection = new Vector3();

  for (let i = 0; i < points.length - 1; i += 1) {
    line.set(points[i], points[i + 1]);
    const found = plane.intersectLine(line, intersection);
    if (found) {
      return {
        point: intersection,
        pointStartIdx: i,
      };
    }
  }

  return undefined;
}

export function getCrossSectionPoints(
  plane,
  origin,
  numPoints,
  object3D,
  raycaster = new Raycaster(),
  visualize = false,
) {
  const points = [];

  const direction = new Vector3();
  plane.projectPoint(new Vector3(0, 1, 0), direction);
  direction.normalize();
  const planeNormal = plane.normal.normalize();

  for (let k = 0; k < numPoints; k += 1) {
    direction.applyAxisAngle(planeNormal, (1 / numPoints) * 2 * Math.PI);
    raycaster.set(origin, direction);

    const hits = raycaster.intersectObject(object3D);
    if (hits.length > 0) {
      points.push(...hits.map(hit => hit.point));

      const { origin: rayOrigin, direction: rayDir } = raycaster.ray;

      if (visualize) {
        const arrow = new ArrowHelper(
          rayDir,
          rayOrigin,
          hits[hits.length - 1].point.distanceTo(rayOrigin),
          0x00ff00,
        );
        global.scene.add(arrow);
      }
    }
  }

  return points;
}

// returns cross section point directly across from specified point
// points should be from getCrossSectionPoints
// points must be even in length
export function getOppositePoint(idx, points) {
  if (points.length % 2 !== 0) {
    throw new Error('number of points must be even');
  }

  return idx < points.length / 2
    ? points[idx + points.length / 2]
    : points[idx - points.length / 2];
}

export function findClosestPointToRay(ray, points) {
  let closestDistSq = Infinity;
  let closestPointIdx;

  points.forEach((p, i) => {
    const dist = ray.distanceSqToPoint(p);
    if (dist < closestDistSq) {
      closestDistSq = dist;
      closestPointIdx = i;
    }
  });

  return {
    point: points[closestPointIdx],
    index: closestPointIdx,
    distance: Math.sqrt(closestDistSq),
  };
}

export function measureCurveLength(curve, startPoint, endPoint, numDivisions = 200) {
  if (startPoint < 0 || endPoint < 0 || startPoint > 1 || endPoint > 1) {
    return 0;
  }

  let length = 0;
  let prevPoint = curve.getPoint(startPoint);
  const stepSize = (endPoint - startPoint) / numDivisions;

  for (let i = 1; i <= numDivisions; i += 1) {
    const curPoint = curve.getPoint(startPoint + i * stepSize);
    length += curPoint.distanceTo(prevPoint);
    prevPoint = curPoint;
  }
  return length;
}

export function getTtoUmapping(curve, t, numDivisions = 200) {
  const distanceToPoint = measureCurveLength(curve, 0, t, numDivisions);
  const totalDistance = measureCurveLength(curve, 0, 1, numDivisions);

  return distanceToPoint / totalDistance;
}

// return true when secondary branch is to the left of main branch from anterior perspective
// Assumes stent is in a general "Y" shape
export const isSecBranchToLeft = stent => {
  const {
    joinSphere: { position: joinPosition },
  } = stent;

  if (!stent.secondary1BranchSpheres.length || !stent.secondary2BranchSpheres.length) {
    return false;
  }

  const sec1Position = stent.secondary1BranchSpheres[0].position;
  const sec2Position = stent.secondary2BranchSpheres[0].position;
  let secBranchMidPoint = new Vector3().subVectors(sec1Position, sec2Position);
  secBranchMidPoint = secBranchMidPoint.setLength(secBranchMidPoint.length() / 2).add(sec2Position);

  const mainDir = joinPosition.clone().sub(secBranchMidPoint);
  const sec1Dir = sec1Position.clone().sub(joinPosition);
  const sec2Dir = sec2Position.clone().sub(joinPosition);

  // angleTo returns absolute value of angle, so account for direction here
  const sign = mainDir.x > 0 ? -1 : 1;

  const zAxis = new Vector3(0, 0, 1);
  const angle = mainDir.normalize().angleTo(zAxis);
  const euler = new Euler(0, sign * angle, 0);

  sec1Dir.applyEuler(euler);
  sec2Dir.applyEuler(euler);

  return sec1Dir.x > sec2Dir.x;
};

// returns true if Vec3s are equal, including specified tolerance
// https://github.com/mrdoob/three.js/issues/7346
export function vec3Equals(v1, v2, tolerance = 0.0000000001) {
  return v1.manhattanDistanceTo(v2) < tolerance;
}

// returns evenly-spaced rays shooting out in fan-like direction between two rays from same origin
// up: vector used to determine rotation direction for rays
// angleStep: angle between each ray
// counterclockwise: rays progress counterclockwise around up vec from dir1 to dir2
export function fanRays(origin, dir1, dir2, up, angleStep, counterclockwise) {
  origin = origin.clone(); // eslint-disable-line no-param-reassign
  dir1 = dir1.clone(); // eslint-disable-line no-param-reassign
  dir2 = dir2.clone(); // eslint-disable-line no-param-reassign

  const ray1 = new Ray(origin, dir1.normalize());
  const ray2 = new Ray(origin, dir2.normalize());

  const normal = ray1.direction
    .clone()
    .cross(ray2.direction)
    .normalize();

  // create plane between two rays
  const fanPlane = new Plane().setFromNormalAndCoplanarPoint(normal, origin);

  // get angle between two rays, taking into account clock direction
  let angleDiff = ray1.direction.angleTo(ray2.direction) * Math3.RAD2DEG;
  let angleStepSign = 1; // positive rotation (counterclockwise)

  const signTest = up.dot(fanPlane.normal);
  if (signTest < 0) {
    // normal vector is opposite up vector
    // so angle direction is negative from dir1 to dir2
    angleDiff = 360 - angleDiff;
  }

  if (!counterclockwise) {
    // positive angle is counterclockwise in right hand coord sys
    angleDiff = 360 - angleDiff;
    angleStepSign = -1; // negative rotation (clockwise)
  }

  // create ray every N degrees, fanning out from origin in between two rays
  const numRays = Math.floor(angleDiff / angleStep);
  const angleStepRads = angleStep * Math3.DEG2RAD * angleStepSign;

  const rays = [];

  for (let i = 0; i <= numRays; i += 1) {
    // must start at dir1 for raycast direction to be correct!
    const rayDir = ray1.direction.clone().applyAxisAngle(fanPlane.normal, i * angleStepRads);
    const ray = new Ray(origin, rayDir);
    rays.push(ray);
  }

  // final ray along ray2 since angle isn't likely to be perfectly divisible
  rays.push(ray2.clone());

  return rays;
}

export const getMouseRaycaster = (camera, x, y, raycaster = new Raycaster()) => {
  const mouse2D = new Vector2(x / camera.right - 1, y / camera.bottom + 1);
  raycaster.setFromCamera(mouse2D, camera);

  return raycaster;
};

export function isPointInFrontOfPlane(plane, point) {
  const coplanarPoint = new Vector3();
  plane.projectPoint(point, coplanarPoint);

  const pointDir = new Vector3().subVectors(point, coplanarPoint);

  // |angle| between pointDir and normal is < 90, therefore point is on same side of normal
  return plane.normal.dot(pointDir) > 0;
}
