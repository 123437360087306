import { isValidDate } from './general';

export const dicomDateStringRegex = /(\d{4}).*?(\d{2}).*?(\d{2})/; // YYYYMMDD

export function getYearFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(0, 4); // dicomDateString is YYYYMMDD
}

export function getMonthFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(4, 6); // dicomDateString is YYYYMMDD
}

export function getDayFromDicomDateString(dicomDateString) {
  return dicomDateString && dicomDateString.substring(6, 8); // dicomDateString is YYYYMMDD
}

// "20181201" -> new Date(2018, 11, 01)
// NOTE: JS date object contructor month index is 0-based!!!!!
export function dicomDateStringToDate(dicomDateString) {
  if (!dicomDateString) {
    return null;
  }

  // check if already date object
  if (isValidDate(dicomDateString)) {
    return dicomDateString;
  }

  const matches = dicomDateString.match(dicomDateStringRegex);
  if (!matches) {
    return null;
  }

  const [, year, month, day] = matches;

  return new Date(Date.UTC(year, parseInt(month, 10) - 1, day));
}

export const isScanExpired = scanDate => {
  if (!scanDate) {
    return false;
  }

  const threeMonthsAgo = new Date();
  const parsedScanDate = dicomDateStringToDate(scanDate);
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  if (parsedScanDate < threeMonthsAgo) {
    return true;
  }
  return false;
};
